import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMemo } from "react";
import { MdOutlineLocationOn } from "react-icons/md";

import { IoCalendarClearOutline } from "react-icons/io5";
import { FaRegClock } from "react-icons/fa6";

import "../NewHome/css/NewHome.css";
import { useRef } from "react";
import { useEffect } from "react";

import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { devApi } from "../../utils/constants";

// Event-Category Images
import EventImage from "../../Assets/Event.svg"
import DanceImage from "../../Assets/Dance.svg"
import FestivalsImage from "../../Assets/Festival.svg"
import FunImage from "../../Assets/Fun.svg"
import GarbaImage from "../../Assets/Garba.svg"
import GatheringImage from "../../Assets/Gathering.svg"
import MoviesImage from "../../Assets/Movies.svg"
import MusicImage from "../../Assets/Music.svg"
import SportImage from "../../Assets/Sport.svg"
import DramaImage from "../../Assets/Drama.svg"
import FitnessImage from "../../Assets/Fitness & Wellness.svg"
import CharityImage from "../../Assets/Charity.svg"
import ComedyImage from "../../Assets/Comedy.svg"
import FairsImage from "../../Assets/Fair.svg"
import BollywoodImage from "../../Assets/Bollywood.svg"
import DefaultEvent from "../../Assets/DefaultEvent.png"
import DefaultUser from "../../Assets/DefaultUser.png"

import { IoMdClose, IoMdSearch } from "react-icons/io";
import { useLoadScript } from "@react-google-maps/api";
// import pluralize from "pluralize";

export default function NewHome({ searchQuery, setSearch, selectedLocation }) {
  const [homepageData, setHomepageData] = useState();
  const [geoLocation, setGeoLocation] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [, setNoExactMatch] = useState(false);
  const [bannerDivIsShowingAllEvents, setBannerDivIsShowingAllEvents] = useState(false);
  const [showBannerTitle, setShowBannerTitle] = useState(true);
  const [showArtistTitle, setShowArtistTitle] = useState(true);
  const [showAllEventsTitle, setShowAllEventsTitle] = useState(false);
  const [showingAllEvents, setShowingAllEvents] = useState(false);
  const navigate = useNavigate();
  const googleApiKey = 'AIzaSyBrtNXfdzBDe5E7ME3DowaF9IhOhoCAxDY';
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleApiKey,
  });

  const sliderRef = useRef(null);
  const upcomingeventref = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.innerSlider.slickGoTo(1);
    }
  }, []);

  useEffect(() => {
    const slider = upcomingeventref.current;
    if (slider) {
      slider.innerSlider.slickGoTo(1);
    }
  }, []);

  useEffect(() => {
    const fetchCityDetails = async () => {
      try {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;

              // console.log("Latitude:", latitude);
              // console.log("Longitude:", longitude);

              const geocodeResponse = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleApiKey}`
              );

              if (geocodeResponse.data.status === "OK") {
                const addressComponents =
                  geocodeResponse.data.results[0]?.address_components || [];

                const cityComponent = addressComponents.find((component) =>
                  component.types.includes("locality")
                );

                setGeoLocation(cityComponent?.long_name || "City not found");
              } else {
                // console.error("Geocoding failed:", geocodeResponse.data.error_message);
                setGeoLocation("Chicago");
              }
            },
            (error) => {
              // console.error("Error fetching geolocation:", error.message);
              setGeoLocation("Chicago");
            }
          );
        } else {
          // console.error("Geolocation is not supported by this browser.");
          setGeoLocation("Geolocation not supported");
        }
      } catch (error) {
        // console.error("Error fetching city:", error);
        setGeoLocation("Error fetching location");
      }
    };

    fetchCityDetails();
  }, [googleApiKey]);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        await axios
          .get(`${devApi}/api/eventgem`)
          .then((response) => {
            if (response.data.success) {
              setHomepageData(response.data.data);
              // console.log("From NewHome.js Component: ", response.data.data);
            }
          })
          .catch((error) => {
            if (error.response.status === 450) {
              toast.error("Session Expired, Please log-in again");
              navigate("/login");
            }
          });
      } catch (error) {
        // console.error("Error fetching event details:", error);
      }
    };

    fetchEventDetails();
  }, [navigate]);

  const multipleEvents = homepageData?.eventsdata.length > 1;
  var settings = {
    dots: false,
    infinite: false,
    autoplay: multipleEvents,
    speed: 4000,
    autoplaySpeed: 4000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: multipleEvents,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: multipleEvents,
          dots: false,
        },
      },
    ],
  };

  const multiplePastEvents = homepageData?.passevents.length > 2;
  var settings1 = {
    dots: false,
    infinite: false,
    autoplay: multiplePastEvents,
    speed: 5000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: multiplePastEvents,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          autoplay: multiplePastEvents,
          arrows: multiplePastEvents,
          dots: false,
        },
      },
    ],
  };

  const categoryImages = {
    Event: EventImage,
    Dance: DanceImage,
    Festivals: FestivalsImage,
    Fun: FunImage,
    Garba: GarbaImage,
    Gathering: GatheringImage,
    Movies: MoviesImage,
    Music: MusicImage,
    Sports: SportImage,
    Charity: CharityImage,
    Comedy: ComedyImage,
    Fairs: FairsImage,
    Theater: DramaImage,
    // Drama: DramaImage,
    Fitness: FitnessImage,
    Concert: BollywoodImage,
    // Bollywood: BollywoodImage,
  };

  useEffect(() => {
    if (searchQuery.trim().length > 0) {
      setSelectedCategory("");
    }
  }, [searchQuery]);

  const artistBanners = useMemo(() => {
    if (!homepageData?.eventsdata) {
      setIsSearchEmpty(true);
      setNoExactMatch(false);
      setShowArtistTitle(false);
      return <div className="text-center w-100 py-2">No events available.</div>;
    }

    const filteredBanners = homepageData.eventsdata.filter((banner) => {
      const title = banner.title || "";
      const artistName = banner.artists?.name || "";

      const eventCategories = banner.categoryName
        ? banner.categoryName.split(",").map((cat) => cat.trim().toLowerCase())
        : [];

      const matchesCategory =
        !selectedCategory || eventCategories.includes(selectedCategory.toLowerCase());

      const matchesSearch =
        searchQuery.trim().length === 0 ||
        title.toLowerCase().includes(searchQuery.trim().toLowerCase()) ||
        artistName.toLowerCase().includes(searchQuery.trim().toLowerCase());

      return matchesSearch && matchesCategory && banner.event_details_temp === 1 && banner.artist_id !== 1;
    });

    setIsSearchEmpty(filteredBanners.length === 0);
    setShowArtistTitle(filteredBanners.length > 0);

    return filteredBanners.length > 0 ? (
      <div className="custom-scrollbar d-flex flex-row overflow-auto gap-4 pb-3 px-2 w-auto">
        {filteredBanners.map((banner, index) => (
          <Link
            to={`/event/${banner.event_id}`}
            key={index}
            className="text-decoration-none text-dark"
          >
            <div
              className="container bg-light d-flex flex-column p-3 rounded-12 shadow-sm artist-card flex-shrink-0"
              style={{ width: "340px" }}
            >
              <div className="fs-5 fw-semibold mb-2 banner-title text-truncate">
                {banner.title}
              </div>
              <div className="d-flex align-items-center mb-2">
                <IoCalendarClearOutline className="flex-shrink-0 icon-color" size={17} />
                <span className="ms-1 banner-date-day">{moment(banner.start_date).format(
                  "DD MMM YYYY"
                )}</span>
                <FaRegClock className="flex-shrink-0 icon-color ms-2" size={16} />
                <span className="ms-1 banner-date-day">
                  {moment(banner.start_date).format("hh:mm A")}
                </span>
              </div>
              <div className="d-flex">
                <MdOutlineLocationOn className="flex-shrink-0 icon-color" size={17} />
                <p
                  className="ms-1 mb-0 text-truncate banner-date-day"
                  style={{
                    maxWidth: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={`${banner.venue_name}, ${banner.location_address_line_1}, ${banner.location_address_line_2 || banner.location_street_number}, ${banner.location_address_line2}, ${banner.location_post_code}`}
                >
                  {banner.venue_name}, {banner.location_address_line_1}, {banner.location_address_line_2 || banner.location_street_number}, {banner.location_address_line2}, {banner.location_post_code}
                </p>
              </div>
              <div className="artist-data d-flex align-items-center gap-3 mt-3">
                <img
                  src={banner.artists.img_full_path || DefaultUser}
                  alt={`artist ${banner.artists.name}`}
                  className="img-fluid rounded-circle"
                  width={30}
                  height={30}
                  style={{ objectFit: "cover", width: "30px", height: "30px" }}
                  onError={(e) => {
                    e.target.src = DefaultUser;
                  }}
                />
                <div className="artist-name d-flex gap-2">{banner.artists.name}</div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    ) : null;
  }, [homepageData?.eventsdata, searchQuery, selectedCategory]);

  const bannerDiv = useMemo(() => {
    if (!homepageData?.eventsdata || homepageData.eventsdata.length === 0) {
      setIsSearchEmpty(true);
      setNoExactMatch(false);
      setShowAllEventsTitle(false);
      return null;
    } else {
      setShowAllEventsTitle(true);
    }

    const cityName = selectedLocation?.value === "all-locations" ? "" : selectedLocation?.label?.toLowerCase() || "";

    // If "all-locations" is selected, we bypass the location filter and show all events.
    const matchesLocation = !cityName || (homepageData.eventsdata.location_address_line_2?.toLowerCase() === cityName);
    const hasLocationEvents = homepageData.eventsdata.some(event => {
      const eventCity = event.location_address_line_2?.toLowerCase() || "";
      return cityName && eventCity === cityName;
    });

    const filteredEvents = homepageData.eventsdata.filter((event) => {
      const eventEndDate = moment(event.end_date);
      const eventStartDate = moment(event.start_date);

      const eventCategories = event.categoryName
        ? event.categoryName.split(",").map((cat) => cat.trim().toLowerCase())
        : [];

      const matchesCategory =
        !selectedCategory || eventCategories.includes(selectedCategory.toLowerCase());

      const matchesSearch =
        event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        ((event.event_details_temp !== 0) &&
          event.artists.name?.toLowerCase().includes(searchQuery.toLowerCase())) ||
        event.categoryName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.organizer.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.venue_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_address_line_1?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_address_line_2?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_post_code?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_state?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_country?.toLowerCase().includes(searchQuery.toLowerCase());

      // Adjust the location match logic
      const isLocationMatch = selectedLocation?.value === "all-locations" || event.location_address_line_2?.toLowerCase().includes(cityName);

      return (
        event.event_paused === 0 &&
        event.event_cancel === 0 &&
        eventEndDate.isSameOrAfter(moment().startOf("day")) &&
        eventStartDate.isBefore(moment().add(2, "weeks")) &&
        matchesCategory &&
        matchesSearch &&
        isLocationMatch
      );
    });

    if (filteredEvents.length === 0) {
      if (!hasLocationEvents || selectedLocation?.value === "all-locations") {
        return (
          <div className="text-center my-auto no-result d-flex flex-column align-items-center justify-content-center w-100 my-3 mb-0">
            <div className="container">
              <div className="row custom-scrollbar flex-wrap flex-lg-nowrap overflow-auto pb-3 mt-0 mt-md-2 mt-lg-2 mt-sm-0">
                {homepageData.eventsdata
                  .map((event) => ({ ...event, dateDiff: moment(event.start_date).diff(moment(), "days") }))
                  .sort((a, b) => a.dateDiff - b.dateDiff)
                  .map((event, index) => {
                    const dateDiff = event.dateDiff;
                    let tag = null;
                    if (dateDiff === 0) tag = "Today";
                    else if (dateDiff === 1) tag = "Tomorrow";

                    return (
                      <div
                        className="col-12 col-md-6 col-lg-3 gx-md-3 gy-md-3 gx-lg-4 gy-lg-0 gy-4 gx-sm-0"
                        key={index}
                      >
                        <div className="position-relative new-card-design p-3 border shadow-sm rounded-12 border-0 h-100 d-flex flex-column">
                          <Link to={`/event/${event.event_id}`} className="text-decoration-none h-100 d-flex flex-column">
                            <div
                              className="w-100"
                              style={{
                                position: "relative",
                                paddingTop: "56.25%",
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                className="position-absolute top-0 start-0 w-100 h-100"
                                loading="lazy"
                                style={{
                                  backgroundImage: `url(${event.event_image_fullpath || DefaultEvent})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  filter: "blur(2px)",
                                  borderRadius: "10px",
                                }}
                              />
                              <div
                                className="position-absolute top-0 start-0 w-100 h-100"
                                width="100%"
                                height="auto"
                                style={{
                                  borderRadius: "10px",
                                  overflow: "hidden",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={event.event_image_fullpath || DefaultEvent}
                                  alt="Event-img"
                                  rel="preload"
                                  as="image"
                                  className="img-fluid w-100 h-100"
                                  style={{
                                    objectFit: event.aspect_ratio !== "16:9" ? "contain" : "cover",
                                    padding: event.aspect_ratio !== "16:9" ? "" : "10px",
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "10px",
                                    clipPath: "inset(0 round 10px)",
                                  }}
                                  onError={(e) => {
                                    e.target.src = DefaultEvent;
                                  }}
                                />
                              </div>
                            </div>
                            <div className="mt-3 flex-grow-1 text-start">
                              <div className="fs-5 fw-semibold mb-2 banner-title text-truncate">{event.title}</div>
                              <div className="d-flex align-items-center mb-2">
                                <IoCalendarClearOutline className="flex-shrink-0 icon-color" size={17} />
                                <span className="ms-1 banner-date-day">{moment(event.start_date).format("DD MMM YYYY")}</span>
                                <FaRegClock className="ms-2 flex-shrink-0 icon-color" size={16} />
                                <span className="banner-date-day ms-1">{moment(event.start_date).format("hh:mm A")}</span>
                              </div>
                              <div className="location-desc d-flex align-items-start my-2">
                                <MdOutlineLocationOn className="flex-shrink-0 me-1 my-auto" size={18} />
                                <div className="flex-grow-1 text-truncate">
                                  {event?.venue_name && `${event?.venue_name}`}
                                  {event?.location_address_line_1 && `, ${event?.location_address_line_1}`}
                                  {event?.location_address_line_2 && `, ${event?.location_address_line_2}`}
                                  {event?.location_post_code && `, ${event?.location_post_code}`}
                                  {event?.location_state && `, ${event?.location_state}`}
                                  {event?.location_country && `, ${event?.location_country}`}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        );
      }

      // If no location events and "all-locations" is not selected, return null
      return null;
    }

    setShowBannerTitle(true);

    return (
      <>
        {filteredEvents
          .map((event) => {
            const dateDiff = moment(event.start_date).diff(moment(), "days");
            return { ...event, dateDiff };
          })
          .sort((a, b) => {
            if (a.dateDiff < 0 && b.dateDiff >= 0) return 1;
            if (b.dateDiff < 0 && a.dateDiff >= 0) return -1;
            if (a.dateDiff === 0 || a.dateDiff === 1) return -1;
            if (b.dateDiff === 0 || b.dateDiff === 1) return 1;
            return a.dateDiff - b.dateDiff;
          })
          .map((event, index) => {
            const dateDiff = event.dateDiff;
            let tag = null;
            if (dateDiff === 0) tag = "Today";
            else if (dateDiff === 1) tag = "Tomorrow";

            return (
              <div
                className="col-12 col-md-6 col-lg-3 gx-md-3 gy-md-3 gx-lg-4 gy-lg-0 gy-4 gx-sm-0"
                key={index}
              >
                <div className="position-relative new-card-design p-3 border shadow-sm rounded-12 border-0 h-100 d-flex flex-column">
                  <Link to={`/event/${event.event_id}`} className="text-decoration-none h-100 d-flex flex-column">
                    <div
                      className="w-100"
                      style={{
                        position: "relative",
                        paddingTop: "56.25%",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        className="position-absolute top-0 start-0 w-100 h-100"
                        loading="lazy"
                        style={{
                          backgroundImage: `url(${event.event_image_fullpath || DefaultEvent})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          filter: "blur(2px)",
                          borderRadius: "10px",
                        }}
                      />

                      <div
                        className="position-absolute top-0 start-0 w-100 h-100"
                        width="100%"
                        height="auto"
                        style={{
                          borderRadius: "10px",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={event.event_image_fullpath || DefaultEvent}
                          alt="Event-img"
                          rel="preload"
                          as="image"
                          className="img-fluid w-100 h-100"
                          style={{
                            objectFit: event.aspect_ratio !== "16:9" ? "contain" : "cover",
                            padding: event.aspect_ratio !== "16:9" ? "" : "10px",
                            height: "100%",
                            width: "100%",
                            borderRadius: "10px",
                            clipPath: "inset(0 round 10px)",
                          }}
                          onError={(e) => {
                            e.target.src = DefaultEvent;
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-3 flex-grow-1">
                      <div className="fs-5 fw-semibold mb-2 banner-title text-truncate">{event.title}</div>
                      <div className="d-flex align-items-center mb-2">
                        <IoCalendarClearOutline className="flex-shrink-0 icon-color" size={17} />
                        <span className="ms-1 banner-date-day">{moment(event.start_date).format("DD MMM YYYY")}</span>
                        <FaRegClock className="ms-2 flex-shrink-0 icon-color" size={16} />
                        <span className="banner-date-day ms-1">{moment(event.start_date).format("hh:mm A")}</span>
                      </div>
                      <div className="location-desc d-flex align-items-start my-2">
                        <MdOutlineLocationOn className="flex-shrink-0 me-1 my-auto" size={18} />
                        <div className="flex-grow-1 text-truncate">
                          {event?.venue_name && `${event?.venue_name}`}
                          {event?.location_address_line_1 && `, ${event?.location_address_line_1}`}
                          {event?.location_address_line_2 && `, ${event?.location_address_line_2}`}
                          {event?.location_post_code && `, ${event?.location_post_code}`}
                          {event?.location_state && `, ${event?.location_state}`}
                          {event?.location_country && `, ${event?.location_country}`}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
      </>
    );
  }, [homepageData?.eventsdata, selectedCategory, searchQuery, selectedLocation?.label, selectedLocation?.value]);

  const allEvents = useMemo(() => {
    if (!homepageData?.eventsdata || homepageData.eventsdata.length === 0) {
      return null;
    }

    const cityName = geoLocation?.trim().toLowerCase() || "";

    // Check if any events exist in the selected location
    const hasLocationEvents = homepageData.eventsdata.some(event => {
      const eventCity = event.location_address_line_2?.trim().toLowerCase() || "";
      return cityName && eventCity.includes(cityName);
    });

    const filteredEvents = homepageData.eventsdata.filter((event) => {
      const eventEndDate = moment(event.end_date);
      const eventStartDate = moment(event.start_date);
      const isPast = eventEndDate.isBefore(moment().startOf("day"));
      const isFutureAfterTwoWeeks = eventStartDate.isAfter(moment().add(2, "weeks"));

      const eventCategories = event.categoryName
        ? event.categoryName.split(",").map((cat) => cat.trim().toLowerCase())
        : [];

      const matchesCategory =
        !selectedCategory || eventCategories.includes(selectedCategory.toLowerCase());

      const matchesSearch =
        event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        ((event.event_details_temp !== 0) &&
          event.artists.name?.toLowerCase().includes(searchQuery.toLowerCase())) ||
        event.categoryName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.organizer.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.venue_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_address_line_1?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_address_line_2?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_post_code?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_state?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_country?.toLowerCase().includes(searchQuery.toLowerCase());

      // Check if "all-locations" is selected, in which case we bypass location matching
      const matchesLocation =
        selectedLocation?.value === "all-locations" || event.location_address_line_2?.toLowerCase().includes(cityName);

      return (
        (isPast || isFutureAfterTwoWeeks) &&
        matchesCategory &&
        matchesSearch &&
        (showingAllEvents || matchesLocation) // If showing all events or location matches
      );
    });

    // Handling cases when no events match
    if (filteredEvents.length === 0) {
      const categoryFilteredEvents = homepageData.eventsdata.filter((event) => {
        const eventCategories = event.categoryName
          ? event.categoryName.split(",").map((cat) => cat.trim().toLowerCase())
          : [];
        return eventCategories.includes(selectedCategory?.toLowerCase());
      });

      // No events found for the selected category
      if (selectedCategory && categoryFilteredEvents.length === 0) {
        return null;
      }

      // If there are events in the location but none match filters, hide section
      if (hasLocationEvents) {
        return null;
      }

      return null;
    }

    return filteredEvents.length > 0
      ? filteredEvents
        .sort((a, b) => {
          if (!a.isPast && b.isPast) return -1;
          if (a.isPast && !b.isPast) return 1;
          return a.startDateDiff - b.startDateDiff;
        })
        .map((event, index) => (
          <div
            className="col-12 col-md-6 col-lg-3 gx-md-3 gy-md-3 gx-lg-4 gy-lg-0 gy-4 gx-sm-0 mt-2"
            key={index}
          >
            <div className="position-relative new-card-design p-3 border shadow-sm rounded-12 border-0 h-100 d-flex flex-column">
              <Link
                to={`/event/${event.event_id}`}
                className="text-decoration-none h-100 d-flex flex-column"
              >
                <div
                  className="w-100"
                  style={{
                    position: "relative",
                    paddingTop: "56.25%",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="position-absolute top-0 start-0 w-100 h-100"
                    loading="lazy"
                    style={{
                      backgroundImage: `url(${event.event_image_fullpath || DefaultEvent})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      filter: "blur(2px)",
                      borderRadius: "10px",
                    }}
                  />

                  <div
                    className="position-absolute top-0 start-0 w-100 h-100"
                    loading="lazy"
                    style={{
                      borderRadius: "10px",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={event.event_image_fullpath || DefaultEvent}
                      alt="Event-img"
                      rel="preload"
                      as="image"
                      className="img-fluid w-100 h-100"
                      style={{
                        objectFit: event.aspect_ratio !== "16:9" ? "contain" : "cover",
                        padding: event.aspect_ratio !== "16:9" ? "" : "10px",
                        height: "100%",
                        width: "100%",
                        borderRadius: "10px",
                        clipPath: "inset(0 round 10px)",
                      }}
                      onError={(e) => {
                        e.target.src = DefaultEvent;
                      }}
                    />
                  </div>
                </div>
                <div className="mt-3 flex-grow-1">
                  <div className="fs-5 fw-semibold mb-2 banner-title text-truncate">
                    {event.title}
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <IoCalendarClearOutline className="flex-shrink-0 icon-color" size={17} />
                    <span className="ms-1 banner-date-day">{moment(event.start_date).format("DD MMM YYYY")}</span>
                    <FaRegClock className="ms-2 flex-shrink-0 icon-color" size={16} />
                    <span className="banner-date-day ms-1">
                      {moment(event.start_date).format("hh:mm A")}
                    </span>
                  </div>
                  <div className="location-desc d-flex align-items-start my-2">
                    <MdOutlineLocationOn className="flex-shrink-0 me-1 my-auto" size={18} />
                    <div className="flex-grow-1 text-truncate">
                      {event?.venue_name && `${event?.venue_name}`}
                      {event?.location_address_line_1 && `, ${event?.location_address_line_1}`}
                      {event?.location_address_line_2 && `, ${event?.location_address_line_2}`}
                      {event?.location_post_code && `, ${event?.location_post_code}`}
                      {event?.location_state && `, ${event?.location_state}`}
                      {event?.location_country && `, ${event?.location_country}`}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        ))
      : null;
  }, [homepageData?.eventsdata, selectedCategory, searchQuery, geoLocation, showingAllEvents, selectedLocation?.value]);

  const shouldShowAllEventsSection = useMemo(() => {
    if (!homepageData?.eventsdata) return false;

    const cityName = geoLocation?.toLowerCase() || "";
    if (!cityName) return true;

    // Check if there are any events for this location in either section
    const hasLocationEventsInBanner = homepageData.eventsdata.some(event => {
      const eventCity = event.location_address_line_2?.toLowerCase() || "";
      const eventEndDate = moment(event.end_date);
      const eventStartDate = moment(event.start_date);
      return cityName && eventCity === cityName &&
        event.event_paused === 0 &&
        event.event_cancel === 0 &&
        eventEndDate.isSameOrAfter(moment().startOf("day")) &&
        eventStartDate.isBefore(moment().add(2, "weeks"));
    });

    const hasLocationEventsInAll = homepageData.eventsdata.some(event => {
      const eventCity = event.location_address_line_2?.toLowerCase() || "";
      const eventEndDate = moment(event.end_date);
      const eventStartDate = moment(event.start_date);
      const isPast = eventEndDate.isBefore(moment().startOf("day"));
      const isFutureAfterTwoWeeks = eventStartDate.isAfter(moment().add(2, "weeks"));
      return cityName && eventCity === cityName &&
        event.event_paused === 0 &&
        event.event_cancel === 0 &&
        (isPast || isFutureAfterTwoWeeks);
    });

    return hasLocationEventsInBanner || hasLocationEventsInAll;
  }, [homepageData?.eventsdata, geoLocation]);

  const filteredEvents = useMemo(() => {
    if (!homepageData?.eventsdata || homepageData.eventsdata.length === 0) {
      return [];
    }

    return homepageData.eventsdata.filter((event) => {
      const eventEndDate = moment(event.end_date);
      const eventStartDate = moment(event.start_date);

      const eventCategories = event.categoryName
        ? event.categoryName.split(",").map((cat) => cat.trim().toLowerCase())
        : [];

      const matchesCategory =
        !selectedCategory || eventCategories.includes(selectedCategory.toLowerCase());

      const matchesSearch =
        event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        ((event.event_details_temp !== 0) &&
          event.artists.name?.toLowerCase().includes(searchQuery.toLowerCase())) ||
        event.categoryName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.organizer.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.venue_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_address_line_1?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_address_line_2?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_post_code?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_state?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.location_country?.toLowerCase().includes(searchQuery.toLowerCase());

      const cityName = geoLocation?.toLowerCase() || "";
      const eventCity = event.location_address_line_2?.toLowerCase() || "";

      const matchesLocation = cityName && eventCity === cityName;

      return (
        event.event_paused === 0 &&
        event.event_cancel === 0 &&
        eventEndDate.isSameOrAfter(moment().startOf("day")) &&
        eventStartDate.isBefore(moment().add(2, "weeks")) &&
        matchesCategory &&
        matchesSearch &&
        (bannerDivIsShowingAllEvents || matchesLocation)
      );
    });
  }, [homepageData?.eventsdata, selectedCategory, searchQuery, geoLocation, bannerDivIsShowingAllEvents]);

  useEffect(() => {
    if (allEvents === null) {
      setShowAllEventsTitle(false);
    } else {
      setShowAllEventsTitle(true);
    }
  }, [allEvents]);

  useEffect(() => {
    const hasResults =
      (artistBanners && artistBanners.length > 0) ||
      (bannerDiv && bannerDiv.length > 0) ||
      (allEvents && allEvents.length > 0);

    setIsSearchEmpty(!hasResults);
  }, [artistBanners, bannerDiv, allEvents]);

  useEffect(() => {
    if (!homepageData?.eventsdata) {
      setIsSearchEmpty(true);
      setNoExactMatch(false);
      setShowAllEventsTitle(false);
    }
  }, [homepageData]);

  useEffect(() => {
    if (geoLocation) {
      setShowAllEventsTitle(true);
    }
  }, [geoLocation]);

  if (!homepageData || !isLoaded) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  };

  if (loadError) {
    // console.error("Error loading Google Maps script:", loadError);
    return <div>Error loading map</div>;
  };

  const festivalCategories = new Set([
    "Diwali", "Navratri/Garba", "Sankranti", "Pongal", "Eid Celebrations", "Ramadan",
    "Onam", "Vishu", "Christmas", "Holi", "Parsi New Year", "New Year Party",
    "Celebration", "Gathering", "Gala", "Devotional", "Cultural Festivals"
  ]);

  const fairsCategories = new Set([
    "Fairs", "Festivals & Fairs", "Carnivals"
  ]);

  const musicCategories = new Set([
    "Music", "Sufi Music", "Singing", "Karaoke", "Live In Concert", "Classical", "Fusion", "Bollywood", "DJ parties", "Ghazal"
  ]);

  const fitness = new Set([
    "Fitness & Wellness"
  ])

  const categorizedList = new Set();
  homepageData?.eventsdata?.forEach((event) => {
    if (event.categoryName) {
      event.categoryName.split(",").forEach((category) => {
        const trimmedCategory = category.trim();
        if (festivalCategories.has(trimmedCategory)) {
          categorizedList.add("Festivals");
        } else if (fairsCategories.has(trimmedCategory)) {
          categorizedList.add("Fairs");
        } else if (musicCategories.has(trimmedCategory)) {
          categorizedList.add("Music");
        } else if (fitness.has(trimmedCategory)) {
          categorizedList.add("Fitness & Wellness");
        } else {
          categorizedList.add(trimmedCategory);
        }
      });
    }
  });

  const categoriesToDisplay = [
    // ...fixedCategories.filter(category => categoryImages[category]),
    // ...Object.keys(categoryImages).filter(category => categoryImages[category] && !fixedCategories.includes(category))
    "Music", "Theater", "Concert", "Sports", "Festivals"
    // ].slice(0, 9);
  ];

  return (
    <>
      <div className="new-bg w-100 h-100">
        <div className="container w-100 h-100" style={{ height: "100vh" }}>
          <div className="d-flex flex-column align-items-start h-100 w-100">

            {/* Search Event */}
            <div className="d-none search-new-home mx-auto mt-2 position-relative">
              <IoMdSearch style={{ color: "#667085" }} />
              <input
                type="text"
                className="form-control border-0 pe-0"
                style={{ color: "#667085" }}
                value={searchQuery}
                placeholder="Search by event, artist, organizer, or city"
                name="search-input"
                autoComplete="off"
                onChange={(e) => setSearch(e.target.value)}
              />
              {searchQuery && (
                <IoMdClose
                  style={{
                    color: "#667085",
                    fontWeight: "600",
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer"
                  }}
                  onClick={() => setSearch("")}
                />
              )}
            </div>

            {/* Categories of Events */}
            <div className="mx-auto container event-type mt-3 mb-1 justify-content-center h-100 w-100" style={{ minHeight: "100%" }}>
              <ul className="d-flex align-items-center justify-content-start overflow-auto custom-scrollbar w-100 flex-nowrap">
                <li className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center align-items-center flex-column" key="all-events">
                  <NavLink
                    to="#"
                    onClick={() => setSelectedCategory("")}
                    className={selectedCategory === "" ? "active-event-category event-category" : "event-category"}
                  >
                    <img
                      rel="preload"
                      as="image"
                      src={categoryImages["Event"]}
                      alt="Category"
                      className="category-image my-2"
                      width="32"
                      height="32"
                    />
                    <span className="event-desc my-2">All Events</span>
                  </NavLink>
                </li>

                {categoriesToDisplay.map((category, index) => {
                  const categoryImage = categoryImages[category] || EventImage;
                  return (
                    <li
                      className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center align-items-center flex-column"
                      key={index}
                    >
                      <NavLink
                        to="#"
                        onClick={() => setSelectedCategory(category)}
                        className={selectedCategory === category ? "active-event-category event-category" : "event-category"}
                      >
                        <img
                          rel="preload"
                          as="image"
                          src={categoryImage}
                          alt="Category"
                          className="category-image my-2"
                          width="32"
                          height="32"
                        />
                        <span className="event-desc my-2">{category}</span>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>

            {geoLocation &&
              (!filteredEvents || filteredEvents.length === 0) &&
              selectedLocation?.value !== "all-locations" &&
              // (!allEvents || allEvents.length === 0) && 
              (
                <h3 className="text-center my-auto mt-3 mb-4 no-result d-flex align-items-center justify-content-center w-100">
                  No events related to "{geoLocation}", but here are some suggestions you might like.
                </h3>
              )}

            {/* Banner Images */}
            {homepageData?.eventsdata && searchQuery.length <= 0 && (
              <>
                {(() => {
                  const seenImages = new Set()

                  const filteredEvents = homepageData.eventsdata
                    .filter((event) => {
                      const eventEndDay = moment(event.end_date);
                      const isValidEvent =
                        event.event_image_fullpath &&
                        event.event_image_fullpath !== DefaultEvent &&
                        eventEndDay.isValid() &&
                        event.event_paused === 0 &&
                        eventEndDay.isSameOrAfter(moment().startOf("day"));
                      if (isValidEvent && !seenImages.has(event.event_image_fullpath)) {
                        seenImages.add(event.event_image_fullpath)
                        return true;
                      }
                      return false;
                    })
                    .sort((a, b) => moment(a.start_date).isBefore(moment(b.start_date)) ? -1 : 1);

                  return filteredEvents.length > 0 ? (
                    <div className="container-fluid mb-4 slide-main-box ev-banner-container w-100 h-100">
                      <Slider lazyLoad="ondemand" {...settings}>
                        {filteredEvents.map((event, index) => (
                          <Link
                            to={`/event/${event.event_id}`}
                            className="rounded-3 overflow-hidden shadow-sm"
                            key={event.event_id || index}
                          >
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "auto",
                                paddingBottom: "56.25%",
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                            >
                              {/* Blurred Background */}
                              <img
                                src={event.event_image_fullpath}
                                alt=""
                                className="position-absolute top-0 start-0 w-100 h-100"
                                loading="lazy"
                                style={{
                                  objectFit: "cover",
                                  objectPosition: "center",
                                  filter: "blur(2px)",
                                }}
                                width="100%"
                                height="auto"
                              />

                              {/* Event Image */}
                              <img
                                src={event.event_image_fullpath}
                                alt={`${event.account_id} ${index + 1}`}
                                width="100%"
                                height="auto"
                                loading="lazy"
                                // rel="preload"
                                // as="image"
                                className="img-fluid rounded w-100 h-100 position-absolute"
                                style={{
                                  objectFit: event.aspect_ratio !== "16:9" ? "contain" : "cover",
                                  top: 0,
                                  left: 0,
                                  zIndex: 1,
                                  padding: event.aspect_ratio !== "16:9" ? "" : "10px",
                                  minHeight: "100%",
                                }}
                                onError={(e) => {
                                  e.target.src = DefaultEvent;
                                }}
                              />
                            </div>
                          </Link>
                        ))}
                      </Slider>
                    </div>
                  ) : null;
                })()}
              </>
            )}

            {searchQuery.trim().length > 0 &&
              isSearchEmpty &&
              (!artistBanners || React.Children.count(artistBanners) === 0) &&
              (!allEvents || React.Children.count(allEvents) === 0) &&
              !bannerDivIsShowingAllEvents && (
                <h3 className="text-center my-auto mt-3 mb-3 no-result d-flex align-items-center justify-content-center w-100">
                  No exact matches found for "{searchQuery}", but here are some suggestions you might like.
                </h3>
              )}

            {showBannerTitle && bannerDiv && (
              <>
                <div className="d-flex align-items-center gap-1 mx-3 mx-sm-3 mx-md-0 mx-lg-0">
                  <p className="home-title mb-2">Popular Upcoming Events</p>
                </div>
                <div className="container">
                  <div className="row custom-scrollbar flex-wrap flex-lg-nowrap overflow-auto pb-3 mt-0 mt-md-2 mt-lg-2 mt-sm-0">
                    {bannerDiv}
                  </div>
                </div>
              </>
            )}

            {showArtistTitle && (
              <>
                {(artistBanners) && (
                  <>
                    <div className="d-flex align-items-center margin gap-1 mx-3 mx-sm-3 mx-md-0 mx-lg-0">
                      <p className="home-title mb-2">Artist Tours and Events</p>
                    </div>

                    <div className="container">
                      <div className="row custom-scrollbar flex-wrap flex-lg-nowrap overflow-auto mt-2">
                        {artistBanners}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {shouldShowAllEventsSection && showAllEventsTitle && allEvents && (
              <>
                <div className="d-flex align-items-center mt-4 gap-1 mx-3 mx-sm-3 mx-md-0 mx-lg-0">
                  <p className="home-title mb-2">Showing All Events</p>
                </div>
                <div className="container mb-3">
                  <div className="row custom-scrollbar flex-wrap flex-lg-nowrap overflow-auto">
                    <div className="row custom-scrollbar flex-wrap flex-lg-nowrap overflow-auto pb-3 mt-0 mt-sm-0 mt-md-3 mt-lg-0">
                      {allEvents}
                    </div>
                  </div>
                </div>
              </>
            )}

            {homepageData?.passevents && homepageData.passevents.length > 0 && searchQuery.trim().length === 0 && (
              <>
                <p className="home-title my-2">Past Events</p>
                {(() => {
                  const seenImages = new Set();

                  const filteredPastEvents = homepageData.passevents
                    .filter((event) => {
                      const eventEndDay = moment(event.end_date);
                      const isValidEvent =
                        event.event_image_fullpath &&
                        event.event_image_fullpath !== DefaultEvent &&
                        eventEndDay.isValid() &&
                        event.event_paused === 0 &&
                        event.event_cancel === 0 &&
                        eventEndDay.isBefore(moment().startOf("day"));

                      if (isValidEvent && !seenImages.has(event.event_image_fullpath)) {
                        seenImages.add(event.event_image_fullpath);
                        return true;
                      }
                      return false;
                    })
                    .sort((a, b) => {
                      if (a.event_id === 29) return -1;
                      if (b.event_id === 29) return 1;
                      return moment(a.start_date).isBefore(moment(b.start_date)) ? -1 : 1
                    }
                    );

                  return filteredPastEvents.length > 0 ? (
                    <div className="container-fluid mb-4 slide-main-box ev-banner-container w-100 h-100">
                      <Slider lazyLoad="ondemand" {...settings1}>
                        {filteredPastEvents.map((event, index) => (
                          <Link
                            to={`/event/${event.event_id}`}
                            className="rounded-3 overflow-hidden shadow-sm"
                            key={event.event_id || index}
                          >
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "auto",
                                paddingBottom: "56.25%",
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                className="position-absolute top-0 start-0 w-100 h-100"
                                loading="lazy"
                                style={{
                                  backgroundImage: `url(${event.event_image_fullpath})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  filter: "blur(2px)",
                                }}
                              />

                              <img
                                src={event.event_image_fullpath}
                                alt={`Past Event ${index + 1}`}
                                width="100%"
                                height="auto"
                                loading="lazy"
                                className="img-fluid rounded w-100 h-100 position-absolute"
                                style={{
                                  objectFit: event.aspect_ratio !== "16:9" ? "contain" : "cover",
                                  top: 0,
                                  left: 0,
                                  zIndex: 1,
                                  padding: event.aspect_ratio !== "16:9" ? "" : "10px",
                                  minHeight: "100%",
                                }}
                                onError={(e) => {
                                  e.target.src = DefaultEvent;
                                }}
                              />
                            </div>
                          </Link>
                        ))}
                      </Slider>
                    </div>
                  ) : null;
                })()}
              </>
            )}
          </div>
          <div>
          </div>
        </div>
      </div>
    </>
  );
}