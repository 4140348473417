import React, { useState } from "react";
import "./css/Banner.css";
import { MdOutlineLocationOn } from "react-icons/md";
import { IoMdAlarm } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import { RiShareBoxLine } from "react-icons/ri";
import {
  decreaseQuantity,
  increaseQuantity,
  updateQuantity,
  // getTotalTickets,
  // getTotalCost,
  setEventDetails,
  initializeTicketTypes,
} from "../../redux/ticket";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

import { IoCalendarClearOutline } from "react-icons/io5";
import { FaRegClock } from "react-icons/fa6";
import { FaInstagram, FaXTwitter, FaFacebook } from "react-icons/fa6";
import axios from "axios";
import { devApi } from "../../utils/constants";
import { RiYoutubeLine } from "react-icons/ri";
import DefaultEvent from "../../Assets/DefaultEvent.png"
import DefaultUser from "../../Assets/DefaultUser.png"
import Countdown from "react-countdown";

export default function Banner({ data, ticketOrder }) {

  const hasMultipleImages = data.data.banners.length > 1;

  var settings = {
    dots: false,
    infinite: hasMultipleImages,
    autoplay: hasMultipleImages,
    speed: 4000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: false,
    adaptiveHeight: false,
    arrows: hasMultipleImages,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          autoplay: hasMultipleImages,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: hasMultipleImages,
          arrows: hasMultipleImages,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          autoplay: hasMultipleImages,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: hasMultipleImages,
          arrows: hasMultipleImages,
          dots: false,
        },
      },
      {
        breakpoint: 420,
        settings: {
          autoplay: hasMultipleImages,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: hasMultipleImages,
          arrows: hasMultipleImages,
          dots: false,
        },
      },
    ],
  };

  const sponsorsWithTier1 = data.data.sponsors.filter(sponsor => sponsor.tier_level === 1);
  // console.log(sponsorsWithTier1);
  const infiniteForTier1 = sponsorsWithTier1.length > 1;
  // console.log(infiniteForTier1);
  const sponsorsWithTier2 = data.data.sponsors.filter(sponsor => sponsor.tier_level === 2);
  // console.log(sponsorsWithTier2);
  const infiniteForTier2 = sponsorsWithTier2.length > 1;
  // console.log(infiniteForTier2);

  // const hasMultipleSponsors = sponsorsWithTier1.length > 1 && sponsorsWithTier2.length > 1;
  // console.log(hasMultipleSponsors);

  var settingsTopTier1 = {
    dots: true,
    infinite: infiniteForTier1,
    autoplay: infiniteForTier1,
    speed: 5000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    variableWidth: true,
    cssEase: "linear",
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          autoplay: infiniteForTier1,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: infiniteForTier1,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          autoplay: infiniteForTier1,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: infiniteForTier1,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 420,
        settings: {
          autoplay: infiniteForTier1,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: infiniteForTier1,
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  var settingsTopTier2 = {
    dots: true,
    infinite: infiniteForTier2,
    autoplay: infiniteForTier2,
    speed: 5000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    variableWidth: true,
    cssEase: "linear",
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          autoplay: infiniteForTier2,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: infiniteForTier2,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          autoplay: infiniteForTier2,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: infiniteForTier2,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 420,
        settings: {
          autoplay: infiniteForTier2,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: infiniteForTier2,
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  const navigate = useNavigate();

  const ticketTypes = useSelector((state) => state.tickets.ticketTypes);
  // const totalCost = useSelector(getTotalCost);
  // const totalTickets = useSelector(getTotalTickets);
  const { inputValue } = useParams();
  const dispatch = useDispatch();
  const [ticketData, setTicketData] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const googleApiKey = 'AIzaSyBrtNXfdzBDe5E7ME3DowaF9IhOhoCAxDY';
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleApiKey,
  });

  // useEffect(() => {
  //   console.log("Component rendered");
  // });

  const visibleTickets = ticketTypes
    .map((ticket, index) => ({ ...ticket, originalIndex: index }))
    .filter(
      (ticket) =>
        ticket.is_hidden !== 1 &&
        ticket.is_paused !== 1 &&
        ticket.sale_status !== 2 &&
        ticket.quantity_available !== null &&
        ticket.quantity_available !== 0
    );

  // Find the nearest start_sale_date that is in the future
  const nearestTicket = visibleTickets.reduce((nearest, ticket) => {
    const ticketTime = new Date(ticket.start_sale_date).getTime();
    const currentTime = Date.now();

    if (ticketTime > currentTime && (!nearest || ticketTime < nearest.time)) {
      return { time: ticketTime, ticket };
    }
    return nearest;
  }, null);

  // Fetch and initialize event and ticket details
  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(
          `${devApi}/api/e/fetchEventDetails?event_id=${inputValue}`
        );
        console.log(response.data);
        const tickets =
          response?.data?.data?.tickets.map((ticket) => ({
            ...ticket,
            quantity: ticket.quantity || 0,
          })) || [];
        const eventDetails = response?.data?.data || {};

        // Update Redux store with fetched data
        dispatch(initializeTicketTypes(tickets));
        dispatch(setEventDetails(eventDetails));

        // Update local state for any additional use
        setTicketData(eventDetails);
      } catch (error) {
        console.error('Error fetching event details:', error);
      }
    };

    fetchEventDetails();
  }, [inputValue, dispatch]);

  // Handlers for ticket quantity changes
  const handleDecrease = (originalIndex) => {
    dispatch(decreaseQuantity({ index: originalIndex }));
  };

  const handleIncrease = (originalIndex) => {
    dispatch(increaseQuantity({ index: originalIndex }));
  };

  const handleQuantityChange = (event, originalIndex) => {
    const newQuantity = parseInt(event.target.value) || 0;
    dispatch(updateQuantity({ index: originalIndex, quantity: Math.max(0, newQuantity) }));
  };

  // Calculate the total cost and total tickets from visible tickets
  const totalCost = visibleTickets.reduce((total, ticket) => {
    return total + (ticket.price * (ticket.quantity || 0));
  }, 0);

  const totalTickets = visibleTickets.reduce((total, ticket) => {
    return total + (ticket.quantity || 0);
  }, 0);

  const handleCartCheckout = async () => {
    // isLoggedin ? navigate("/duPageTicket") : navigate("/checkout");
    // navigate("/checkout?from=event-details");
    navigate(`/checkout?from=event-${inputValue}`);
  };

  const [placeId, setPlaceId] = useState("");
  const [venueName, setVenueName] = useState("");

  useEffect(() => {
    if (!data?.data?.event || !googleApiKey) return;

    const addressComponents = [
      data.data.event.venue_name,
      data.data.event.location_address_line_1,
      data.data.event.location_address_line_2 || data.data.event.location_street_number,
      data.data.event.location_address_line2,
      data.data.event.location_state,
      data.data.event.location_country,
    ].filter(Boolean);

    const addressLines = addressComponents.join(", ");

    const fetchLocationDetails = async () => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(addressLines)}&key=${googleApiKey}`
        );

        const result = response.data;
        if (!result || result.status !== "OK" || result.results.length === 0) {
          console.error("Error fetching location details");
          return;
        }

        const locationData = result.results[0];
        setCoordinates(locationData.geometry.location);
        setVenueName(locationData.formatted_address);
      } catch (error) {
        console.error("Error fetching location details:", error);
      }
    };

    fetchLocationDetails();
  }, [data?.data?.event, googleApiKey]);

  const handleGetDirection = () => {
    if (placeId) {
      window.open(`https://www.google.com/maps/place/?q=place_id:${placeId}`, "_blank");
    } else if (venueName) {
      window.open(`https://www.google.com/maps/search/${encodeURIComponent(venueName)}`, "_blank");
    } else if (coordinates) {
      window.open(`https://www.google.com/maps?q=${coordinates.lat},${coordinates.lng}`, "_blank");
    } else {
      console.error("Location details are not available yet.");
    }
  };

  if (!isLoaded) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  };

  if (loadError) {
    console.error("Error loading Google Maps script:", loadError);
    return <div>Error loading map</div>;
  }

  return (
    <div className="container mt-3">
      {data.data.event.event_paused === 1 || data?.data?.event?.event_cancel === 1 ? (
        <>
          <div className="d-flex align-items-center justify-content-center h-screen">
            <div className="col-lg-6">
              <h1 className="text-center">Event is Not Available</h1>
            </div >
          </div >
        </>
      ) : (
        <>
          <div className="row">
            {/* Left side: Event Image and Details */}
            <div className="col-lg-8 col-md-12">
              <div className="row">
                {/* Event Banner Slider */}
                {data.data.banners.length > 0 ? (
                  <div className="col-12">
                    <div className="slide-main-box ev-banner-container">
                      <Slider className="banner-image" {...settings}>
                        {data.data.banners?.map((image, index) => (
                          <div className="banner-wrapper" key={index}>
                            <div
                              className="banner-bg"
                              style={{ backgroundImage: `url(${image.banner_img_fullpath || DefaultEvent})` }}
                            ></div>
                            <img
                              id={index}
                              src={image.banner_img_fullpath || DefaultEvent}
                              alt="Event-img"
                              className="banner-img"
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                ) : (
                  <div className="col-12">
                    <div className="slide-main-box ev-banner-container">
                      <div className="banner-wrapper">
                        <div
                          className="banner-bg"
                          style={{ backgroundImage: `url(${DefaultEvent})` }}
                        ></div>
                        <img
                          src={DefaultEvent}
                          alt="Event-img"
                          className="banner-img"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Event Details */}
                <div className="col-12">
                  <div className="my-2 lg-my-2 md-my-2 sm-my-2">
                    <div className="d-flex flex-column md-flex-row items-center gap-2 my-4 mb-1">
                      <div className="title">{data.data.event.title}</div>
                      <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
                        <IoCalendarClearOutline
                          className="flex-shrink-0"
                          style={{ width: "16px", height: "16px", color: "#667085" }}
                        />
                        <p className="info mb-0">{moment(data.data.event.start_date).format(
                          "DD MMMM YYYY"
                        )}</p>
                        {/* <p className="info mb-0">{data.data.event.startday} - {data.data.event.endDay}</p>
                        <h6 className="info mb-0">{data.data.event.start_date} - {data.data.event.end_date}</h6> */}
                        <FaRegClock
                          className="flex-shrink-0"
                          style={{ width: "18px", height: "18px", color: "#667085" }}
                        />
                        <p className="info mb-0">
                          {moment(data.data.event.start_date).format("hh:mm A")}
                        </p>
                      </div>
                      {/* <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
                          <BiCategory
                            className="flex-shrink-0"
                            style={{ width: "18px", height: "18px", color: "#667085" }}
                          />
                          <p className="info mb-0">
                            {data.data.event.categoryName}
                          </p>
                        </div> */}
                    </div>

                    <div className="d-sm-flex align-items-center bidding-offer d-none">
                      {data.data.event.early_bird ? (
                        <>
                          <IoMdAlarm
                            style={{ width: "24px", height: "24px", color: "#23BA20" }}
                          />
                          <h4>Early Bird Offer </h4>
                          <span>( Ends on {data.data.event.early_bird_end_date})</span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                {/* Description and About section */}
                <div className="col-12">
                  <p style={{ whiteSpace: "normal", textAlign: "justify" }} className="info-data-desc my-2">
                    {data.data.event.description}
                  </p>
                </div>

                {data?.data?.event?.special_instruction && (
                  <div className="col-12">
                    <p className="special-instruction mt-3 mb-1">Special Instructions</p>
                    <p style={{ whiteSpace: "normal", textAlign: "justify" }} className="info-data-desc my-2">
                      {data.data.event.special_instruction}
                    </p>
                  </div>
                )}

                {/* Slider Section */}
                {data.data.sponsors?.length > 0 &&
                  data.data.sponsors.some((sponsor) => sponsor.tier_level === 1) && (
                    <div className="user-org-box my-4">
                      <div className="org-details">
                        {(() => {
                          const tier1Sponsors = data.data.sponsors.filter(
                            (sponsor) => sponsor.tier_level === 1
                          );

                          if (tier1Sponsors.length > 0) {
                            let tierName = tier1Sponsors[0]?.tier_name?.trim() || "";
                            tierName = tierName.replace(/\bSponsor[s]?\b\s*$/gi, "").trim();
                            const hasSponsor = /\bSponsor[s]?\b/i.test(tierName);
                            return (
                              <h2 className="sponsors">
                                {tierName} {!hasSponsor ? (tier1Sponsors.length > 1 ? " Sponsors" : " Sponsor") : ""}
                              </h2>
                            );
                          }
                        })()}

                        {/* Slider Section */}
                        <div className="mt-2">
                          <Slider {...settingsTopTier1}>
                            {data.data.sponsors
                              ?.filter((sponsor) => sponsor.tier_level === 1)
                              ?.map((image, index) => (
                                <div
                                  className="d-flex flex-column align-items-center gap-3"
                                  key={index}
                                >
                                  <img
                                    src={image.sponsor_img_fullpath || image.sponsor_img}
                                    alt={`Sponsor ${image.tier_name}`}
                                    className="img-fluid winterGalaSpimages w-100 h-auto object-fit-contain"
                                    style={{ height: "20px" }}
                                  />
                                </div>
                              ))}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  )}

                {/*Buy Now Fixed Bottom for Mobile */}
                {data?.data?.tickets.length > 0 && (
                  <div className="navbar fixed-bottom navbar-light bg-light px-2 d-sm-none py-2">
                    {/* Check if the event has ended */}
                    {data?.data?.event.end_date && new Date(data.data.event.end_date) < new Date() ? (
                      <div className="d-flex align-items-center justify-content-center w-100 my-2 text-center">
                        <div className="sale-status-2 text-center">
                          We’re Sorry! This Event Has Ended.
                        </div>
                      </div>
                    ) : (
                      <>
                        {/* Prioritize "Sale Has Ended" message when all tickets have sale_status === 2 */}
                        {data?.data?.tickets.every((ticket) => ticket.sale_status === 2) ? (
                          <div
                            className="d-flex align-items-center justify-content-center w-100 my-2 text-center"
                            style={{ color: "#f98a45", fontWeight: "600" }}
                          >
                            <div className="sale-status-2 mt-1">
                              We’re Sorry! This Sale Has Ended.
                            </div>
                          </div>
                        ) : data?.data?.tickets.every(
                          (ticket) =>
                            ticket.sale_status === 1 || ticket.quantity_available === null || ticket.quantity_available === 0
                        ) ? (
                          <div
                            className="d-flex align-items-center justify-content-center w-100 my-2 text-center"
                            style={{ color: "#f98a45", fontWeight: "600" }}
                          >
                            We’re Sorry! No tickets available for booking
                          </div>
                        ) : visibleTickets.every((ticket) => new Date(ticket.start_sale_date).getTime() > Date.now()) ? (
                          (() => {
                            // Find the nearest start_sale_date
                            const nearestTicket = visibleTickets.reduce((nearest, ticket) => {
                              const ticketTime = new Date(ticket.start_sale_date).getTime();
                              if (!nearest || ticketTime < nearest.time) {
                                return { time: ticketTime, ticket };
                              }
                              return nearest;
                            }, null);

                            if (nearestTicket) {
                              return (
                                <div className="text-center w-100">
                                  <div className="sale-start-title mb-3">Event Starts in</div>
                                  <Countdown
                                    date={nearestTicket.time} // Target start_sale_date
                                    renderer={({ days, hours, minutes, seconds }) => (
                                      <div className="d-flex sale-timer justify-content-center gap-3">
                                        <div className="d-flex flex-column align-items-center">
                                          <span className="number">{days}</span>
                                          <span className="desc">Days</span>
                                        </div>
                                        <div className="number">:</div>
                                        <div className="d-flex flex-column align-items-center">
                                          <span className="number">{hours}</span>
                                          <span className="desc">Hours</span>
                                        </div>
                                        <div className="number">:</div>
                                        <div className="d-flex flex-column align-items-center">
                                          <span className="number">{minutes}</span>
                                          <span className="desc">Minutes</span>
                                        </div>
                                        <div className="number">:</div>
                                        <div className="d-flex flex-column align-items-center">
                                          <span className="number">{seconds}</span>
                                          <span className="desc">Seconds</span>
                                        </div>
                                      </div>
                                    )}
                                  />
                                </div>
                              );
                            }
                            return null;
                          })()
                        ) : (
                          <>
                            <div className="total d-flex ms-2 mt-0">
                              <span className="ticket-price-mobile">Starts From</span>{" "}
                              ${visibleTickets
                                ?.filter((ticket) => ticket.is_hidden !== 1 && ticket.is_paused !== 1 && ticket.quantity_available > 0)
                                ?.reduce((minPrice, ticket) => Math.min(minPrice, ticket.price), Infinity)}
                            </div>

                            <button
                              className="buy-now-fixed d-flex align-items-center"
                              disabled={
                                new Date(data.data.event.end_date) < new Date() ||
                                data?.data?.tickets.every(
                                  (ticket) => ticket.sale_status === 1 || ticket.sale_status === 2 || ticket.quantity_available === 0
                                )
                              }
                              style={{
                                opacity:
                                  new Date(data.data.event.end_date) < new Date() ||
                                    data?.data?.tickets.every(
                                      (ticket) => ticket.sale_status === 1 || ticket.sale_status === 2 || ticket.quantity_available === 0
                                    )
                                    ? 0.8
                                    : 1,
                              }}
                              onClick={handleCartCheckout}
                            >
                              <span>Book Now</span>
                              <FaArrowRight size={20} />
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}

              </div>
            </div>

            {/* Right side: Ticket Information, Venue, and Sponsors */}
            <div className="col-lg-4 col-md-12 mt-2 mt-lg-0">
              {/* Ticket Information */}
              {data?.data?.event.end_date && new Date(data.data.event.end_date) < new Date() ? (
                <div className="card p-4 mb-4 border-0 rounded-sm ticket-d-none">
                  <div className="sale-status-2 mt-1 text-center">
                    We’re Sorry! This Event Has Ended.
                  </div>
                </div>
              ) : (
                data?.data?.tickets.length > 0 && visibleTickets.length > 0 && (
                  <div className="card p-4 mb-4 border-0 rounded-sm ticket-d-none">
                    {!visibleTickets.every(
                      (ticket) =>
                        ticket.sale_status === 2 ||
                        new Date(ticket.start_sale_date).getTime() > Date.now() ||
                        ticket.quantity_available === null ||
                        ticket.quantity_available === 0
                    ) && (
                        <p className="card-heading mb-3">Available Tickets</p>
                      )}

                    {visibleTickets.every((ticket) => ticket.sale_status === 2) ? (
                      <div className="text-center my-2" style={{ color: '#f98a45', fontWeight: '600' }}>
                        <div className="sale-status-2 mt-1">
                          We’re Sorry! This Sale Has Ended.
                        </div>
                      </div>
                    ) : (
                      <>
                        {/* Find the nearest start_sale_date in the future */}
                        {visibleTickets.every(
                          (ticket) => new Date(ticket.start_sale_date).getTime() > Date.now()
                        ) && (() => {
                          const nearestTicket = visibleTickets.reduce((nearest, ticket) => {
                            const ticketTime = new Date(ticket.start_sale_date).getTime();
                            const currentTime = Date.now();

                            // Only consider tickets whose start_sale_date is in the future
                            if (ticketTime > currentTime && (!nearest || ticketTime < nearest.time)) {
                              return { time: ticketTime, ticket };
                            }
                            return nearest;
                          }, null);

                          if (nearestTicket) {
                            return (
                              <div className="text-center my-3">
                                <div className="sale-start-title mb-3">Event Starts in</div>
                                <Countdown
                                  date={nearestTicket.time} // Target start_sale_date
                                  renderer={({ days, hours, minutes, seconds }) => (
                                    <div className="d-flex sale-timer justify-content-center gap-3">
                                      <div className="d-flex flex-column align-items-center">
                                        <span className="number">{days}</span>
                                        <span className="desc">Days</span>
                                      </div>
                                      <div className="number">:</div>
                                      <div className="d-flex flex-column align-items-center">
                                        <span className="number">{hours}</span>
                                        <span className="desc">Hours</span>
                                      </div>
                                      <div className="number">:</div>
                                      <div className="d-flex flex-column align-items-center">
                                        <span className="number">{minutes}</span>
                                        <span className="desc">Minutes</span>
                                      </div>
                                      <div className="number">:</div>
                                      <div className="d-flex flex-column align-items-center">
                                        <span className="number">{seconds}</span>
                                        <span className="desc">Seconds</span>
                                      </div>
                                    </div>
                                  )}
                                />
                              </div>
                            );
                          }

                          return null;
                        })()}

                        {visibleTickets
                          .filter((ticket) => ticket.quantity_available !== null && ticket.quantity_available > 0)
                          .map((ticket) => {
                            if (ticket.sale_status === 2) {
                              return (
                                <div key={ticket.id} className="text-center w-100 sale-status-2 mb-3">
                                  We’re Sorry! This Sale Has Ended.
                                </div>
                              );
                            }

                            if (visibleTickets.quantity_sold >= visibleTickets.quantity_available) {
                              return (
                                <div key={ticket.id} className="d-flex justify-content-between align-items-center mb-3 ticket-data gap-2">
                                  <div className="d-flex flex-column">
                                    <div className="card-title d-block">{ticket.title}</div>
                                    <div className="cardTitleDes">{ticket.description}</div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <span className="sale-status-1">Sold Out</span>
                                  </div>
                                </div>
                              );
                            }

                            if (new Date(ticket.start_sale_date).getTime() < Date.now()) {
                              return (
                                <div key={ticket.id} className="d-flex justify-content-between align-items-center mb-3 ticket-data gap-2">
                                  <div className="d-flex flex-column">
                                    <div className="card-title d-block">{ticket.title}</div>
                                    <div className="cardTitleDes">{ticket.description}</div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <div className="price-data pe-2">
                                      {parseFloat(ticket.price) === 0 ? "Free" : `$${ticket.price}`}
                                    </div>
                                    <div className="ticket-quantity d-flex align-items-center">
                                      <button
                                        disabled={data.data.event.isPast || ticket.quantity <= 0 || ticket.quantity === null || ticket.min_per_person === null}
                                        style={{
                                          opacity:
                                            data.data.event.isPast || ticket.quantity <= 0 || ticket.quantity === null || ticket.min_per_person === null
                                              ? 0.5
                                              : 1,
                                        }}
                                        onClick={() => handleDecrease(ticket.originalIndex)}
                                      >
                                        -
                                      </button>
                                      <label htmlFor={`ticket-${ticket.originalIndex}`} className="sr-only">
                                        Quantity
                                      </label>
                                      <input
                                        type="number"
                                        value={ticket.quantity ?? 0}
                                        className="text-center border-0 custom-input-number"
                                        name="ticket"
                                        id={`ticket-${ticket.originalIndex}`}
                                        style={{ width: '20px', padding: '0', background: 'none' }}
                                        onChange={(e) => handleQuantityChange(e, ticket.originalIndex)}
                                        readOnly
                                        min={0}
                                        autoComplete="off"
                                      />
                                      <button
                                        disabled={
                                          data.data.event.isPast ||
                                          ticket.quantity >= ticket.max_per_person ||
                                          ticket.quantity === null ||
                                          ticket.max_per_person === null ||
                                          (ticket.quantity_available - ticket.quantity_sold) === 0 ||
                                          (ticket.quantity + 1) > (ticket.quantity_available - ticket.quantity_sold)
                                        }
                                        style={{
                                          opacity:
                                            data.data.event.isPast ||
                                              ticket.quantity >= ticket.max_per_person ||
                                              ticket.quantity === null ||
                                              ticket.max_per_person === null ||
                                              (ticket.quantity_available - ticket.quantity_sold) === 0 ||
                                              (ticket.quantity + 1) > (ticket.quantity_available - ticket.quantity_sold)
                                              ? 0.5
                                              : 1,
                                        }}
                                        onClick={() => handleIncrease(ticket.originalIndex)}
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            }

                            return null;
                          })}
                      </>
                    )}
                    {visibleTickets.some((ticket) => ticket.sale_status !== 2 && new Date(ticket.start_sale_date).getTime() < Date.now()) && (
                      <div className="d-flex justify-content-between align-items-center">
                        {/* Check if all tickets have sale_status: 1 */}
                        {visibleTickets.every((ticket) => ticket.sale_status === 1) ? (
                          <>
                            <div className="sale-status-1-total">
                              Total: ${isNaN(totalCost) || data.data.event.isPast ? 0 : totalCost.toFixed(2)}
                            </div>
                            <button
                              className="book-now sale-status-1-btn"
                              onClick={handleCartCheckout}
                              disabled={true}
                            >
                              <span>Book Now</span>
                              <FaArrowRight size={18} />
                            </button>
                          </>
                        ) : (
                          <>
                            <div className="total">
                              Total: ${isNaN(totalCost) || data.data.event.isPast ? 0 : totalCost.toFixed(2)}
                            </div>
                            <button
                              className="book-now"
                              onClick={handleCartCheckout}
                              disabled={totalTickets === 0 || data.data.event.isPast}
                              style={{
                                opacity: totalTickets === 0 || data.data.event.isPast ? 0.8 : 1,
                              }}
                            >
                              <span>Book Now</span>
                              <FaArrowRight size={18} />
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )
              )}

              {/* Venue Information */}
              <div className="card p-3 pb-2 mb-2 border-0 rounded-12">
                <div className="d-flex justify-content-between">
                  <p className="card-heading mb-2">Venue</p>
                  <button aria-label="Get Direction" className="direction d-flex align-items-center gap-1" onClick={handleGetDirection}>
                    Get Directions
                    <RiShareBoxLine size={14} style={{ color: "#667085" }} />
                  </button>
                </div>
                <div className="location-desc d-flex align-items-start my-2">
                  <MdOutlineLocationOn className="flex-shrink-0 me-2 my-auto" size={18} />
                  <div className="flex-grow-1">
                    {data.data?.event.venue_name && `${data.data?.event.venue_name}`}
                    {data.data?.event.location_address_line_1 &&
                      `, ${data.data?.event.location_address_line_1}`}
                    {(data.data?.event.location_address_line_2 || data.data.event.location_street_number) &&
                      `, ${(data.data?.event.location_address_line_2 || data.data.event.location_street_number)}`}
                    {data.data?.event.location_address_line2 &&
                      `, ${data.data?.event.location_address_line2}`}
                    {data.data?.event.location_post_code &&
                      `, ${data.data?.event.location_post_code}`}
                    {data.data?.event.location_state &&
                      `, ${data.data?.event.location_state}`}
                    {data.data?.event.location_country &&
                      `, ${data.data?.event.location_country}`}
                  </div>
                </div>
                <div className="map my-2 rounded">
                  {coordinates ? (
                    <div className="map" style={{ height: "125px", width: "100%" }}>
                      {isLoaded ? (
                        <GoogleMap
                          zoom={12}
                          center={coordinates}
                          mapContainerStyle={{ height: "100%", width: "100%", borderRadius: "10px" }}
                        >
                          <Marker position={coordinates} onClick={handleGetDirection} />
                        </GoogleMap>
                      ) : (
                        <div>Loading map...</div>
                      )}
                    </div>
                  ) : (
                    <div>Loading coordinates...</div>
                  )}
                </div>
                {data?.data?.event?.venue_intruction && (
                  <ul className="venue-desc mt-2 d-flex flex-column gap-1">
                    <li>{data?.data?.event?.venue_intruction}</li>
                  </ul>
                )}
              </div>
            </div>

            <div className="col-lg-8 col-md-12">
              {/* Organizer, Artist and Gold Sponsors Section */}
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column col-lg-12 col-md-12 col-sm-12 col-12">
                  {/* Organizer Section */}
                  {data.data.event.organiser_id ? (
                    <>
                      <h2 className="org-head-user mt-3 mb-3">Meet Your Organizer</h2>
                      <div className="d-flex flex-column gap-4 w-light p-3 org-details" style={{ width: "100%" }}>
                        <div className="d-flex flex-column gap-4" style={{ width: "100%" }}>
                          {data?.data?.organiser?.map((organiser, index) => (
                            <div key={index} className="d-flex gap-4 align-items-start">
                              {/* Organizer Image */}
                              <div className="user-org-box my-auto">
                                <img
                                  src={organiser?.logo_path_fullpath ? organiser.logo_path_fullpath : DefaultUser}
                                  alt={organiser.name}
                                  onError={(e) => {
                                    e.target.src = DefaultUser;
                                  }}
                                />
                              </div>

                              {/* Organizer Details */}
                              <div className="d-flex flex-column w-100">
                                <div className="d-flex flex-column justify-content-between">
                                  <div>
                                    <div className="org-name m-0">{organiser?.name}</div>
                                    <div className="m-0 org-description mb-2" style={{ textAlign: "justify" }}>
                                      {organiser?.about || ""}
                                    </div>
                                  </div>

                                  {/* Social Media Icons */}
                                  <div className="d-flex gap-3 justify-content-end w-100">
                                    {organiser?.twitter && (
                                      <Link
                                        to={organiser?.twitter}
                                        target="_blank"
                                        style={{
                                          pointerEvents: organiser?.twitter ? "auto" : "none",
                                          cursor: organiser?.twitter ? "pointer" : "default",
                                        }}
                                      >
                                        <FaXTwitter style={{ width: "19px", height: "32px", color: "#98A2B3" }} />
                                      </Link>
                                    )}
                                    {organiser?.facebook && (
                                      <Link
                                        to={organiser?.facebook}
                                        target="_blank"
                                        style={{
                                          pointerEvents: organiser?.facebook ? "auto" : "none",
                                          cursor: organiser?.facebook ? "pointer" : "default",
                                        }}
                                      >
                                        <FaFacebook style={{ width: "21px", height: "32px", color: "#98A2B3" }} />
                                      </Link>
                                    )}
                                    {organiser?.instagram_url && (
                                      <Link
                                        to={organiser?.instagram_url}
                                        target="_blank"
                                        style={{
                                          pointerEvents: organiser?.instagram_url ? "auto" : "none",
                                          cursor: organiser?.instagram_url ? "pointer" : "default",
                                        }}
                                      >
                                        <FaInstagram style={{ width: "20px", height: "32px", color: "#98A2B3" }} />
                                      </Link>
                                    )}
                                    {organiser?.youtube_url && (
                                      <Link
                                        to={organiser?.youtube_url}
                                        target="_blank"
                                        style={{
                                          pointerEvents: organiser?.youtube_url ? "auto" : "none",
                                          cursor: organiser?.youtube_url ? "pointer" : "default",
                                        }}
                                      >
                                        <RiYoutubeLine style={{ width: "28px", height: "32px", color: "#98A2B3" }} />
                                      </Link>
                                    )}
                                  </div>
                                </div>
                                {/* <div className="d-flex justify-content-end align-items-centner mt-3">
                                  <Link to="/organiserT&C" className="text-decoration-none">
                                    <button className="terms-btn w-100">
                                      Terms and Conditions{" "}
                                      <FaArrowRight size={18} />
                                    </button>
                                  </Link>
                                </div> */}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/* Artist Details */}
                  {data.data.event.event_details_temp === 1 && (
                    <>
                      <h2 className="org-head-user mt-32 mb-3">Know Your Artist</h2>
                      <div
                        className="d-flex flex-column gap-4 w-light p-3 org-details"
                        style={{ width: "100%" }}
                      >
                        <div
                          className="d-flex gap-4 align-items-start"
                          style={{ width: "100%" }}
                        >
                          {/* Artist Image */}
                          <div className="user-org-box">
                            <img
                              src={data.data.artists?.img_full_path ? data.data.artists.img_full_path : DefaultUser}
                              alt={data?.data?.artists.name}
                              onError={(e) => {
                                e.target.src = DefaultUser;
                              }}
                            />
                          </div>

                          {/* Artist Details */}
                          <div className="d-flex flex-column justify-content-between w-100">
                            <div>
                              <h5 className="org-name m-0">{data.data.artists.name}</h5>
                              <h6
                                className="m-0 org-description mb-1"
                                style={{ textAlign: "justify" }}
                              >
                                {data.data.artists.description}
                              </h6>
                            </div>
                            {/* Social Media */}
                            <div className="d-flex gap-3 justify-content-end w-100">
                              {/* Twitter Link */}
                              {data?.data?.artists?.twitter_link && (
                                <Link
                                  to={data.data.artists.twitter_link}
                                  target="_blank"
                                  style={{
                                    pointerEvents: "auto",
                                    cursor: "pointer",
                                  }}
                                >
                                  <FaXTwitter
                                    style={{
                                      width: "19px",
                                      height: "32px",
                                      color: "#98A2B3",
                                    }}
                                  />
                                </Link>
                              )}

                              {/* Facebook Link */}
                              {data?.data?.artists?.fb_link && (
                                <Link
                                  to={data.data.artists.fb_link}
                                  target="_blank"
                                  style={{
                                    pointerEvents: "auto",
                                    cursor: "pointer",
                                  }}
                                >
                                  <FaFacebook
                                    style={{
                                      width: "21px",
                                      height: "32px",
                                      color: "#98A2B3",
                                    }}
                                  />
                                </Link>
                              )}

                              {/* Instagram Link */}
                              {data?.data?.artists?.insta_link && (
                                <Link
                                  to={data.data.artists.insta_link}
                                  target="_blank"
                                  style={{
                                    pointerEvents: "auto",
                                    cursor: "pointer",
                                  }}
                                >
                                  <FaInstagram
                                    style={{
                                      width: "20px",
                                      height: "32px",
                                      color: "#98A2B3",
                                    }}
                                  />
                                </Link>
                              )}

                              {/* YouTube Link */}
                              {data?.data?.artists?.youtube_link && (
                                <Link
                                  to={data.data.artists.youtube_link}
                                  target="_blank"
                                  style={{
                                    pointerEvents: "auto",
                                    cursor: "pointer",
                                  }}
                                >
                                  <RiYoutubeLine
                                    style={{
                                      width: "28px",
                                      height: "32px",
                                      color: "#98A2B3",
                                    }}
                                  />
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Sponsors Section */}
                  {data.data.sponsors?.length > 0 && data.data.sponsors.some((sponsor) => sponsor.tier_level === 2) && (
                    <div className="user-org-box mb-3 mb-lg-5 mt-32">
                      <div className="org-details">
                        {(() => {
                          const tier1Sponsors = data.data.sponsors.filter(
                            (sponsor) => sponsor.tier_level === 2
                          );

                          if (tier1Sponsors.length > 0) {
                            let tierName = tier1Sponsors[0]?.tier_name?.trim() || "";
                            tierName = tierName.replace(/\bSponsor[s]?\b\s*$/gi, "").trim();
                            const hasSponsor = /\bSponsor[s]?\b/i.test(tierName);
                            return (
                              <h2 className="sponsors">
                                {tierName} {!hasSponsor ? (tier1Sponsors.length > 1 ? " Sponsors" : " Sponsor") : ""}
                              </h2>
                            );
                          }
                        })()}
                        {/* Slider Section */}
                        <div className="mt-2">
                          <Slider {...settingsTopTier2}>
                            {data.data.sponsors
                              ?.filter((sponsor) => sponsor.tier_level === 2)
                              ?.map((image, index) => (
                                <div
                                  className="d-flex flex-column align-items-center gap-3"
                                  key={index}
                                >
                                  <img
                                    src={image.sponsor_img_fullpath || image.sponsor_img}
                                    alt={`Sponsor ${image.tier_name}`}
                                    className="img-fluid winterGalaSpimages w-100 h-auto object-fit-contain"
                                    style={{ height: "20px" }}
                                  />
                                  {/* {image.tier_zipcode && (
                                    <p className="text-center mt-2 fw-semibold">
                                      {image.tier_zipcode}
                                    </p>
                                  )} */}
                                </div>
                              ))}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )
      }
    </div>
  );
}