import React, { useEffect, useState } from "react";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./Components/EventDetails/HomePage";
import Login from "./Components/Login/Login";
import Signup from "./Components/Login/Signup";
import ReactGA from "react-ga4";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./Components/Profile/Profile";
import PageNotFound from "./Components/Error/PageNotFound";
import PaymentSuccess from "./Components/Payments/PaymentSuccess";
import PaymentFailed from "./Components/Payments/PaymentFailed";
import ProfileHome from "./Components/Profile/ProfileHome";
import OrderHistory from "./Components/Profile/OrderHistory";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import GoogleCallback from "./Components/Login/SocialLogin";
import ResetPassword from "./Components/ForgotPassword/ResetPassword";
import TermsAndConditions from "./Components/FooterLegalData/TermsAndConditions";
import CookiePolicy from "./Components/FooterLegalData/CookiePolicy";
import NewHome from "./Components/NewHome/NewHome";
import PrivacyPolicy from "./Components/FooterLegalData/PrivacyPolicy";
import HeaderDetails from "./Components/EventDetails/HeaderDetails";
import VerifyEmail from "./Components/Login/VerifyEmail";
import Render from "./Components/ViewSetter/Render";
import FAQ from "./Components/FooterLegalData/FAQ";
import OrgTermsAndConditions from "./Components/FooterLegalData/OrgTermsAndConditions";
import BackToTop from "./Components/ViewSetter/BackToTop";
import DMDeviceCamera from "./Components/DoorManagement/DMDeviceCamera";
import LoginDM from "./Components/DoorManagement/LoginDM";
import FacebookPolicy from "./Components/FooterLegalData/FacebookPolicy";
// import DupageHomepage from "./Components/EventDetails/DupageHomepage";
import AdminLayout from "./Components/AdminLayouts/AdminLayout";
import PaymentSuccessDupage from "./Components/Payments/PaymentSuccessDupage";
import DupageHome from "./Components/Dupage/DupageHome";
import OrgLogin from "./Components/OrganizerLogin/OrgLogin";
import OrgSignup from "./Components/OrganizerLogin/OrgSignup";
import DMOptions from "./Components/DoorManagement/DMOptions";
import DMBluetoothDevice from "./Components/DoorManagement/DMBluetoothDevice";
import DMDetails from "./Components/DoorManagement/DMDetails";
import InvalidDmLink from "./Components/DoorManagement/InvalidDmLink";
import Checkout from "./Components/Checkout/Checkout";
import DupageTickets from "./Components/EventDetails/DupageTickets";
import FooterDetails from "./Components/EventDetails/FooterDetails";
import AboutUs from "./Components/FooterLegalData/AboutUs";
import Notification from "./Components/Profile/Notification";
import SearchHandler from "./Components/EventDetails/SearchHandler";
import { useSelector } from "react-redux";

const App = () => {
  const [currentUrl, setCurrentUrl] = useState(window.location.pathname);
  const [searchEvent, setSearchEvent] = useState("");
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const doorManagerLogin = useSelector((state) => state.user.doorManagerLogin);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const TRACKING_ID = "G-Q5LRLHSLW9";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({
    hitType: "pageview",
    page: "/dupagefairevent",
    title: "Dupage County Fair",
  });
  useEffect(() => {
    const handleUrlChange = () => {
      setCurrentUrl(window.location.pathname);
    };
    window.addEventListener("popstate", handleUrlChange);
    return () => {
      window.removeEventListener("popstate", handleUrlChange);
    };
  }, [currentUrl]);

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={
        <div className="d-flex justify-content-center align-items-center ndf">
          <div className="loader-box">
            <span></span>
            <span></span>
          </div>
        </div>
      } //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <>
        <BrowserRouter>
          <BackToTop />
          <Render />
          <SearchHandler searchEvent={searchEvent} />
          <HeaderDetails searchQuery={searchEvent} setSearchEvent={setSearchEvent} setSelectedLocation={setSelectedLocation} />
          <Routes>
            {/* <Route exact path="/" element={<Home />} /> */}
            <Route exact path="/" element={<NewHome searchQuery={searchEvent} setSearch={setSearchEvent} selectedLocation={selectedLocation} />} />
            <Route path="/event/:inputValue" element={<HomePage />} />
            {/* <Route exact path="/dupagefairevent" element={<DupageHomepage />} />
            <Route
              path="/dupagefairevent/:inputValue"
              element={<DupageHomepage />}
            /> */}
            {/* <Route path="/login" element={<Login />} /> */}
            <Route
              path="/login"
              element={isLoggedIn ? <Navigate to="/" /> : <Login />}
            />

            <Route path="/signup" element={<Signup />} />
            <Route path="/duPageTicket" element={<DupageTickets />} />
            <Route path="/verifyemail" element={<VerifyEmail />} />
            {/* <Route path="/event/:inputValue/checkout" element={<Checkout />} /> */}
            <Route path="checkout" element={<Checkout />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/paymentSuccess" element={<PaymentSuccess />} />
            <Route
              path="/paymentsuccessdupage"
              element={<PaymentSuccessDupage />}
            />
            <Route path="/paymentFailed" element={<PaymentFailed />} />
            {/* <Route path="/doormanagement" element={<LoginDM />} /> */}
            <Route
              path="/doormanagement"
              element={doorManagerLogin ? <Navigate to="/camerascanner" /> : <LoginDM />}
            />
            <Route path="/doormanagerdetails" element={<DMDetails />} />
            <Route path="/invalidlink" element={<InvalidDmLink />} />

            <Route path="/scanner" element={<DMOptions />} />
            <Route path="/devicescanner" element={<DMBluetoothDevice />} />
            <Route path="/camerascanner" element={<DMDeviceCamera />} />
            <Route path="/userhome" element={<ProfileHome />}>
              <Route path="profile" element={<Profile />} />
              <Route path="notifications" element={<Notification />} />
              <Route path="orderHistory" element={<OrderHistory />} />
            </Route>
            <Route path="/resetpassword" element={<ResetPassword />} />
            {/* <Route path="/resetpassword/*" element={<ResetPassword />} /> */}
            <Route
              path="/termsandconditions"
              element={<TermsAndConditions />}
            ></Route>
            <Route path="/facebookPolicy" element={<FacebookPolicy />}></Route>
            <Route path="/aboutus" element={<AboutUs />}></Route>
            <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
            <Route path="/cookiepolicy" element={<CookiePolicy />}></Route>
            <Route path="/faq" element={<FAQ />}></Route>
            <Route
              path="/organiserT&C"
              element={<OrgTermsAndConditions />}
            ></Route>
            <Route path="/panel" element={<AdminLayout />} />
            <Route path="/dashboard" element={<DupageHome />} />
            <Route path="/organizerlogin" element={<OrgLogin />} />
            <Route path="/organizersignup" element={<OrgSignup />} />
            <Route path="/api/*" element={<GoogleCallback />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <FooterDetails />
        </BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    </CacheBuster>
  );
};

export default App;