import React, { useEffect, useRef, useState } from "react";

// Styles
import "./css/QrScanner.css";

// Qr Scanner
import QrScanner from "qr-scanner";
import QrFrame from "../../Assets/qr-frame.svg";
import axios from "axios";
import { devApi } from "../../utils/constants";
import { Modal } from "react-bootstrap";
import { FaBars, FaRegCheckCircle } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { RxCross2, RxCrossCircled } from "react-icons/rx";
import { HiOutlineQrcode } from "react-icons/hi";
import { LuTicket } from "react-icons/lu";
import { MdOutlineLocationOn, MdOutlineQrCodeScanner } from "react-icons/md";
import { FaArrowRight, FaRegClock } from "react-icons/fa6";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoCalendarClearOutline } from "react-icons/io5";

// "#D9D9D9"
// "#024DDF"
const DMDeviceCamera = () => {
  // QR States
  const scanner = useRef();
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [qrOn, setQrOn] = useState(true);
  const [showTicketCountLoad, setShowTicketCountLoad] = useState(false);
  const [eventsData, setEventsData] = useState(null);
  const [showScannerLoad, setScannerLoad] = useState(true);
  const [showCloseEntriesLoad, setCloseEntriesLoad] = useState(false);
  const [videoElCurrent, setVideoElCurrent] = useState();
  const [showModal, setShowModal] = useState(false);
  const [attendeeData, setAttendeeData] = useState();
  const [message, setMessage] = useState();
  const [result, setResult] = useState(false);
  const [closeEntryTrigger, setCloseEntryTrigger] = useState(false);
  const [ticketScannedData, setTicketScannedData] = useState();
  const [dmDashboardData, setDmDashboard] = useState();
  const token = useSelector((state) => state.user.token);
  const doorManagerToken = useSelector((state) => state.user.doorManagerToken)
  const user = useSelector((state) => state.user.user);
  const doorManagerLink = useSelector((state) => state.user.doorManagerLink);
  const [dmLogout, setDmLogout] = useState(false);
  const location = useLocation();
  // Result
  const [scannedResult, setScannedResult] = useState("");
  const [alreadyScannedData, setAlreadyScannedData] = useState();
  const [networkError, setNetworkError] = useState(false);
  const [timeStamp, setTimeStamp] = useState(false);
  const [color, setColor] = useState("");
  const [activeTab, setActiveTab] = useState("Scanner");
  const [mobilenavbar, setMobilenavbar] = useState(false);
  const [initiateScan, setInitiateScan] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const navigate = useNavigate();

  const getDmDashboard = async () => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + doorManagerToken,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };

    await axios
      .post(`${devApi}/api/dm/doorManagerDashboard`, null, config)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data);
          setEventsData(response.data.events);
          setDmDashboard(response.data);
        } else {
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (attendeeData?.ticket) {
      switch (attendeeData?.ticket.id) {
        case 47:
          setColor("#df6d02");
          break;
        case 48:
          setColor("#d43832");
          break;
        case 49:
          setColor("#024DDF");
          break;
        case 50:
          setColor("#e7c649");
          break;
        case 51:
          setColor("#116704");
          break;
        default:
          setColor("024DDF");
          break;
      }
    }
  }, [attendeeData]);
  // Success

  const onScanSuccess = async (result) => {
    if (!scanner.current) return;
    scanner.current.stop();
    setScannedResult(result?.data);
    setAlreadyScannedData();
    setTimeStamp(false);
    setResult(false);
    setShowModal(false);
    setNetworkError(false);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + doorManagerToken,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const body = { qr_code: result.data };

    try {
      const response = await axios.post(`${devApi}/api/dm/scanAttendeeQR`, body, config);

      if (response.data.success) {
        if (response.data.door_manager.logout || response.data.door_manager.admin_entry_closed) {
          setDmLogout(true);
          navigate(`/doormanagement${doorManagerLink}`);
        } else {
          console.log("Scan Success:", response.data);
          setAttendeeData(response.data);
          setAlreadyScannedData(null);
          setMessage("");
          setShowModal(true);
          setResult(true);
        }
      } else {
        if (response.data.attendee) {
          setAlreadyScannedData(response.data);
          setTimeStamp(true);
        } else {
          setAlreadyScannedData(null);
          setMessage(response.data.message);
        }
        setResult(false);
        setShowModal(true);
      }
    } catch (error) {
      console.error("Scan Error:", error);
      setNetworkError(true);
      setMessage("Network error. Please try again.");
      setShowModal(true);
      setResult(false);
    }
  };

  // Fail
  const onScanFail = (err) => { };

  const scan = () => {
    setShowModal(false);
    setNetworkError(false);
    if (scanner.current) {
      scanner.current.start();

      scanner.current.onScanSuccess = (result) => {
        console.log("Scan result:", result);

        if (result?.success) {
          setAlreadyScannedData(result);
          setTimeStamp(true);
        } else {
          setAlreadyScannedData(result);
          setTimeStamp(false);
        }
      };

      scanner.current.onScanError = (error) => {
        console.error("Scan error:", error);
        setAlreadyScannedData({ success: false, message: "Scanning failed" });
        setTimeStamp(false);
      };
    }
  };

  useEffect(() => {
    console.log("Updated alreadyScannedData:", alreadyScannedData);
  }, [alreadyScannedData]);

  // const detectDevice = async () => {
  //   navigator.bluetooth
  //     .requestDevice({
  //       filters: [{ name: "BarCode Scanner BLE" }],
  //       services: ["0000180f-0000-1000-8000-00805f9b34fb"], // Required to access service later.
  //     })
  //     .then((device) => {
  //       console.log("device", device);
  //       return device.gatt?.connect();
  //     })
  //     .then((server) => {
  //       console.log("server", server);
  //       return server.getPrimaryService("0000180f-0000-1000-8000-00805f9b34fb");
  //     })
  //     .then((service) => {
  //       console.log("sevice", service);
  //       return service.getCharacteristic(
  //         "0000180f-0000-1000-8000-00805f9b34fb-0x1200e3bb4a"
  //       );
  //     })
  //     .then((characteristic) => {
  //       // Reading Battery Level…
  //       return characteristic.readValue();
  //     })
  //     .then((value) => {
  //       console.log(`QR ${value.getUint8(0)}`);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const getTicketCount = async () => {
    setShowTicketCountLoad(true);
    setScannerLoad(false);
    setCloseEntriesLoad(false);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + doorManagerToken,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };

    await axios
      .post(`${devApi}/api/dm/doorManagerDashboard`, null, config)
      .then((response) => {
        if (response.data.success) {
          if (
            response.data.door_manager.logout ||
            response.data.door_manager.admin_entry_closed
          ) {
            scanner.current.stop();
            setDmLogout(true);
            setShowTicketCountLoad(false);
            setMessage();
            setShowModal(false);
            setResult(false);
            const intervalCall = setTimeout(() => {
              navigate(`/doormanagement${doorManagerLink}`);
            }, 4000);
            return () => {
              // clean up
              clearTimeout(intervalCall);
            };
          } else {
            setShowTicketCountLoad(true);
            setTicketScannedData(response.data);
          }
        } else {
          setShowTicketCountLoad(false);
          setScannerLoad(true);
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (videoEl?.current && showScannerLoad) {
      setVideoElCurrent(true);
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl?.current || undefined,
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    } else {
      setVideoElCurrent(false);
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (!videoElCurrent) {
        scanner?.current?.stop();
      }
    };
  }, [showScannerLoad]);

  useEffect(() => {
    getDmDashboard().then(() => {
      if (location.state && location.state.entry_closed) {
        setCloseEntryTrigger(true);
      }
    });
  }, []);

  const startQRScanner = async () => {
    // console.log("Starting QR Scanner...");
    if (videoEl.current && videoEl.current.srcObject) {
      let stream = videoEl.current.srcObject;
      let tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      videoEl.current.srcObject = null;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
      if (videoEl.current) {
        videoEl.current.srcObject = stream;
        videoEl.current.play();
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const stopQRScanner = () => {
    // console.log("Stopping QR Scanner...");

    if (videoEl.current && videoEl.current.srcObject) {
      let stream = videoEl.current.srcObject;
      let tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      videoEl.current.srcObject = null;
    }
  };

  useEffect(() => {
    if (activeTab === "Scanner") {
      setInitiateScan(false);
      startQRScanner();
    } else {
      setInitiateScan(false);
      stopQRScanner();
    }
    return () => stopQRScanner();
  }, [activeTab]);

  const getbackToScanner = () => {
    setInitiateScan(false);
    setScannerLoad(true);
    setCloseEntriesLoad(false);
    setShowTicketCountLoad(false);
  };

  const handleCloseEntries = () => {
    setScannerLoad(false);
    setCloseEntriesLoad(true);
    setShowTicketCountLoad(false);
  };

  const handleTicketCountHide = () => {
    setShowTicketCountLoad(false);
    setActiveTab("Scanner");
    setInitiateScan(false);
    setShowTicketCountLoad(false);
    setScannerLoad(true);
  };

  const closeEntriesForDM = async () => {
    setCloseEntriesLoad(false);
    getDmDashboard();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + doorManagerToken,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };

    await axios
      .post(`${devApi}/api/dm/entryClose`, null, config)
      .then((response) => {
        if (response.data.success) {
          setVideoElCurrent(false);
          scanner.current.stop();
          setScannerLoad(false);
          setShowTicketCountLoad(false);
          setCloseEntryTrigger(true);
        } else {
          setCloseEntryTrigger(true);
        }
      })
      .catch((error) => { });
    //getTicketCount();
  };

  const handleScanAgain = async () => {
    setTimeout(() => setInitiateScan(false), 1000);
    setActiveTab("Scanner");
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + doorManagerToken,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };

    await axios
      .post(`${devApi}/api/dm/entryOpen`, null, config)
      .then((response) => {
        if (response.data.success) {
          setScannerLoad(true);
          setCloseEntryTrigger(false);
        } else {
        }
      })
      .catch((error) => { });

  };

  const handleEntryClosed = () => {
    setCloseEntriesLoad(false);
    setScannerLoad(true);
  };

  useEffect(() => {
    if (videoEl.current) {
      const computedStyle = window.getComputedStyle(videoEl.current);
      const transformValue = computedStyle.getPropertyValue("transform");

      setIsFlipped(transformValue.includes("matrix(-1"));
    }
  }, [activeTab, qrOn]);

  useEffect(() => {
    if (!qrOn)
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn]);

  useEffect(() => {
    console.log("alreadyScannedData:", alreadyScannedData);
    console.log("timeStamp:", timeStamp);
  }, [alreadyScannedData, timeStamp]);

  return (
    <div className="container qr-reader">
      <div className="doormanager-event-desc my-4">
        {eventsData && (
          <>
            <div className="doormanager-title">{eventsData.title}</div>
            <div className="d-flex gap-2 align-items-center my-2">
              <IoCalendarClearOutline
                className="flex-shrink-0"
                style={{ width: "16px", height: "16px", color: "#667085" }}
              />
              <p className="info mb-0">{moment(eventsData.start_date).format(
                "DD MMMM YYYY"
              )}
              </p>
              <FaRegClock
                className="flex-shrink-0 ms-3"
                style={{ width: "18px", height: "18px", color: "#667085" }}
              />
              <p className="info mb-0">
                {moment(eventsData.start_date).format("hh:mm A")}
              </p>
            </div>
            <div className="doormanager-location d-flex align-items-start my-2">
              <MdOutlineLocationOn className="flex-shrink-0 me-2 my-auto" size={18} style={{ color: "#667085" }} />
              <div className="flex-grow-1">
                {eventsData.venue_name && `${eventsData.venue_name}`}
                {eventsData.location_address_line_1 &&
                  `, ${eventsData.location_address_line_1}`}
                {(eventsData.location_address_line_2 || eventsData.location_street_number) &&
                  `, ${(eventsData.location_address_line_2 || eventsData.location_street_number)}`}
                {eventsData.location_address_line2 &&
                  `, ${eventsData.location_address_line2}`}
                {eventsData.location_post_code &&
                  `, ${eventsData.location_post_code}`}
                {eventsData.location_state &&
                  `, ${eventsData.location_state}`}
                {eventsData.location_country &&
                  `, ${eventsData.location_country}`}
              </div>
            </div>
          </>
        )}
      </div>
      {closeEntryTrigger ? (
        <div className="w-100 d-flex justify-content-center position-fixed bottom-20" style={{ zIndex: 2 }}>
          <button className="get-started w-75 d-flex justify-content-center align-items-center"
            onClick={() => {
              setInitiateScan(true);
              setTimeout(() => handleScanAgain(), 1000);
            }}
          >
            Continue Scanning
          </button>
        </div>
      ) : (
        <div className="d-flex justify-content-between w-100 mt-3">
          <div className="d-flex">
            {/* Mobile Menu Toggle */}
            <div className="d-block d-lg-none">
              <div
                className={mobilenavbar ? "navbar-mobile-profile nav-link-active position-relative" : "navbar-profile"}
              >
                <div className="d-flex align-items-center gap-2">
                  <FaBars className="data-nav-toggle" size={20} onClick={() => setMobilenavbar(!mobilenavbar)} />
                  <div
                    onClick={() => setMobilenavbar(!mobilenavbar)}
                    className="active-tab-name"
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                  >
                    {activeTab}
                  </div>
                </div>

                {mobilenavbar && (
                  <ul className="position-absolute h-auto">
                    <li className="d-flex flex-row align-items-center gap-2 show-hide" style={{ width: "max-content" }}>
                      <LuTicket size={24} color={activeTab === "Ticket Status" ? "#000" : "#667085"} />
                      <div
                        onClick={() => {
                          setActiveTab("Ticket Status");
                          setShowTicketCountLoad(true);
                          setMobilenavbar(false);
                          getTicketCount();
                          setInitiateScan(false);
                        }}
                        className="menu-item"
                      >
                        Ticket Status
                      </div>
                    </li>
                    <hr className="header-hr w-100 p-0 m-0" />
                    <li className="d-flex flex-row align-items-center gap-2 show-hide">
                      <HiOutlineQrcode size={24} color={activeTab === "Scanner" ? "#000" : "#667085"} />
                      <div
                        onClick={() => {
                          setActiveTab("Scanner");
                          setMobilenavbar(false);
                          setInitiateScan(false);
                        }}
                        className="menu-item"
                      >
                        Scanner
                      </div>
                    </li>
                  </ul>
                )}
              </div>
            </div>

            {/* Desktop Menu */}
            <div className="d-flex gap-3 d-none d-lg-flex">
              <div
                className={`d-flex flex-column align-items-center justify-content-center mx-2 ${activeTab === "Ticket Status" ? "active-menu" : ""
                  }`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setActiveTab("Ticket Status");
                  setInitiateScan(false);
                  setShowTicketCountLoad(true);
                  getTicketCount();
                }}
              >
                <LuTicket size={24} color={activeTab === "Ticket Status" ? "#000" : "#667085"} />
                <h5 className="option-text">Ticket Status</h5>
              </div>

              <div
                className={`d-flex flex-column align-items-center justify-content-center mx-2 ${activeTab === "Scanner" ? "active-menu" : ""
                  }`}
                style={{ cursor: "pointer" }}
                onClick={() => { setActiveTab("Scanner"); setInitiateScan(false); }}
              >
                <HiOutlineQrcode size={24} color={activeTab === "Scanner" ? "#000" : "#667085"} />
                <h5 className="option-text">Scanner</h5>
              </div>
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-center gap-1 mx-2"
            style={{ cursor: "pointer" }}
            onClick={handleCloseEntries}
          >
            <RxCross2
              size={24}
              color={showCloseEntriesLoad ? "#475467" : ""}
            />
            <h5 className="option-text m-0">Close Entries</h5>
          </div>
        </div>
      )}

      {/* QR */}
      {activeTab === "Scanner" && (
        <div className="qr-reader d-flex flex-column align-items-center justify-content-center">
          {showTicketCountLoad ? (
            <div></div>
          ) : closeEntryTrigger ? (
            dmDashboardData && (
              <>
                <div
                  className="data-back d-flex align-items-center justify-content-center my-2"
                  style={{ transform: `scaleX(${isFlipped ? -1 : 1})` }}
                >
                  <div className="d-flex flex-column align-items-center justify-content-center mx-2 position-static">
                    <div>
                      <h5 className="ticket-scan-head">Tickets Status</h5>
                    </div>
                    <div>
                      <small className="total-ticket-scan">Total Tickets Scanned</small>
                    </div>
                    <div>
                      <h5 className="scan-total">{dmDashboardData.scancount}</h5>
                    </div>
                  </div>
                </div>
                <h5 className="my-3 entry-close-text">Entries Closed</h5>
              </>
            )
          ) : dmLogout ? (
            <div
              className="data-back d-flex align-items-center justify-content-center my-2"
              style={{ transform: `scaleX(${isFlipped ? -1 : 1})` }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center mx-2">
                <div className="my-2">
                  <h5 className="ticket-scan-head">Entries Stopped</h5>
                </div>
                <div className="my-2 text-center">
                  <small>Your account has been deactivated by Admin. Logging out.</small>
                </div>
                <div className="my-2 text-center">
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </div>
              </div>
            </div>
          ) : (
            <div className="qr-container">
              {/* Video Feed (Camera) */}
              <video ref={videoEl} key={activeTab} className="video-box" style={{ transform: "scaleX(-1)" }}></video>

              {/* QR Frame (Positioned inside video) */}
              <div ref={qrBoxEl} className="qr-box scan-region-highlight">
                <img src={QrFrame} alt="QR Frame" className="qr-frame" />
              </div>
            </div>
          )}
        </div>
      )}

      {/* Attendee */}
      {showModal && (
        <Modal
          show={showModal}
          onHide={() => { setShowModal(false); scan(); }}
          centered
        >
          <Modal.Body className="p-0">
            <div className="new-bg rounded d-flex justify-content-between bg-light flex-column align-items-center mb-2">
              {/* <div className="overlayScan"> */}
              <div className="matter w-100 new-bg rounded px-4 py-3">
                {result ? (
                  <>
                    <div className="d-flex align-items-center mt-3 gap-3">
                      <div className="change-bg-success">
                        <FaRegCheckCircle size={24} color="#1EA133" />
                      </div>
                      <h5 className="failedScan m-0">Success</h5>
                    </div>
                    <div className="d-flex justify-content-evenly gap-4 align-items-center my-3 result-header">
                      <div className="d-flex flex-column gap-3">
                        <div>
                          <div className="total-ticket-scan">Attendee Name</div>
                          <div className="attendee-details">{attendeeData.attendee.first_name}</div>
                        </div>
                        <div>
                          <div className="total-ticket-scan">Ticket Type</div>
                          <div className="attendee-details">{attendeeData.ticket.title}</div>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-3">
                        <div>
                          <div className="total-ticket-scan">Time</div>
                          <div className="attendee-details">
                            {moment(attendeeData?.attendee.arrival_time).format(
                              "hh:mm A"
                            )}
                          </div>
                        </div>
                        <div>
                          <div className="total-ticket-scan">Ticket ID</div>
                          <div className="attendee-details">{attendeeData?.attendee?.private_reference_number}</div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="my-2 stay-btn w-100 d-flex justify-content-center align-items-center gap-2"
                      style={{ borderRadius: 20, minWidth: "30%" }}
                      onClick={scan}
                    >
                      <small className="">Scan Next</small>
                      <FaArrowRight />
                    </button>
                  </>
                ) : (
                  <>
                    <div className="d-flex flex-row justify-content-between align-items-center mx-3 my-2">
                      <div className="d-flex justify-items-center align-items-center gap-3 w-100">
                        <div className="change-bg-danger">
                          <RxCrossCircled size={30} color="#AE1414" />{" "}
                        </div>
                        <div className="d-flex flex-column ml-3 gap-2 w-100">
                          <h5 className="failedScan m-0">
                            {networkError
                              ? "Network Error"
                              : alreadyScannedData && timeStamp
                                ? "Already Scanned"
                                : "Scan Failed"}
                          </h5>
                          <p className="text-desc m-0">
                            {networkError ? (
                              "Scanning has been failed due to network issues."
                            ) : alreadyScannedData ? (
                              <>
                                {alreadyScannedData?.message}{" on "}
                                {moment(alreadyScannedData?.attendee.arrival_time).format("Do MMM, YYYY hh:mm A")}
                              </>
                            ) : (
                              <div>Invalid QR: "QR code not recognized."</div>
                            )}
                          </p>
                        </div>
                        <button
                          className="bg-none border-0 ms-3"
                          onClick={scan}
                        >
                          <RxCross2 size={20} color="#98A2B3" />
                        </button>
                      </div>
                    </div>
                    <button
                      className="my-2 doormanager-tryAgain justify-items-center align-items-center w-100"
                      onClick={scan}
                    >
                      <small className="resultButtons">Try Again</small>
                    </button>
                  </>
                )}
              </div>
              {/* </div> */}
            </div>
          </Modal.Body>
        </Modal >
      )}

      {initiateScan && (
        <Modal
          show={initiateScan}
          onHide={() => setInitiateScan(false)}
          centered
        >
          <Modal.Body>
            <div className="d-flex flex-column justify-content-center mx-2">
              <div>
                <h5 className="ticket-scan-head">Scanning is initiated</h5>
                <p className="total-ticket-scan">You can scan the tickets now</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {/* Show Ticket Status */}
      {showTicketCountLoad && activeTab === "Ticket Status" ? (
        <Modal
          show={showTicketCountLoad}
          onHide={handleTicketCountHide}
          centered
        >
          <Modal.Body className="total-scanned-ticket-modal">
            {ticketScannedData ? (
              <div className="d-flex flex-column align-items-center justify-content-center mx-2">
                <div>
                  <h5 className="ticket-scan-head">Tickets Status</h5>
                </div>
                <div>
                  <small className="total-ticket-scan">Total Tickets Scanned</small>
                </div>
                <div>
                  <h5 className="scan-total mt-2">{ticketScannedData.scancount}</h5>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center mx-2">
                <div className="loader-box">
                  <span></span>
                  <span></span>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      ) : (
        <div></div>
      )
      }

      {/* Entry Close */}
      {showCloseEntriesLoad ? (
        <Modal show={showCloseEntriesLoad} onHide={handleEntryClosed} centered className="border-0">
          <Modal.Body className="new-bg border-0" style={{ borderRadius: "12px" }}>
            <div className="d-flex flex-column align-items-center justify-content-center mx-2">
              <div className="d-flex gap-3 align-items-center my-2">
                <small className="failedScan">Are you sure you want to close entries?</small>
                <button onClick={() => {
                  setCloseEntriesLoad(false);
                  scan();
                }} className="border-0 bg-none">
                  <RxCross2 size={20} className="ms-3" />
                </button>
              </div>
              <div className="d-flex flex-row my-2 justify-content-center w-100">
                <button
                  className="stay-btn mx-2 col-6"
                  onClick={handleEntryClosed}
                >
                  Cancel
                </button>
                <button
                  className="get-started mx-2 col-6"
                  onClick={closeEntriesForDM}
                >
                  Close Entries
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )
      }
    </div >
  );
};

export default DMDeviceCamera;