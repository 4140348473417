import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom/dist";
import "./css/ForgotPassword.css";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { devApi } from "../../utils/constants";
// import { verifyEmail } from "../../APICalls/forgotPasswordCalls";
import axios from "axios";
import validator from "validator";
import { toast } from "react-toastify";
import { LuKeyRound } from "react-icons/lu";
import { IoMdArrowRoundBack } from "react-icons/io";
import { CiCircleCheck } from "react-icons/ci";
import { FiMail } from "react-icons/fi";

export default function ForgotPassword() {
  const token = useSelector((state) => state.user.token);
  const signupDetails = useSelector((state) => state.user.signupDetails);
  const [loading, setLoading] = useState(false);
  const [emailField, setEmailField] = useState(true);
  const [otpsent, setOtpsent] = useState(false);
  const [newPasswordField, setNewPasswordField] = useState(false);
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [showModal, setShowModal] = useState(false);
  const { state } = useLocation();

  const [data, setData] = useState({
    email: "",
  });
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [messages, setMessages] = useState({
    emailError: "",
    emailSuccess: "",
    emailCheck:
      "Do check your spam and junk email folder if you don't find email in your primary inbox",
  });

  const updateData = (e) => {
    if (messages.emailError) {
      setMessages({ ...messages, emailError: null });
    }
    setData({
      ...data,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
  };

  const resetPassword = async () => {
    setLoading(true);
    if (!validator.isEmail(data.email)) {
      setMessages({ ...messages, emailError: "Please enter a valid email" });
      toast.error("Please Enter Valid Email");
      setLoading(false);
      return;
    }
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    };
    const body = {
      email: data.email,
    };

    await axios
      .post(`${devApi}/api/resetPasswordLink`, body, config)
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setDisable(true);
          setMessages({ ...messages, emailSuccess: response.data.message });
          toast.success(response.data.message);
        } else {
          setLoading(false);

          setMessages({ ...messages, emailError: response.data.message });
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setMessages({ ...messages, emailError: error.response.data.message });
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/login");
        }
      });

    // setOtpsent(true);
    // setNewPasswordField(true);
    // setEmailField(false);
  };

  const reset = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (otpsent === true && seconds > 0) {
      const timerInterval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timerInterval);
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [otpsent, seconds]);

  const remainingSeconds = seconds % 60;
  const verifyOtp = () => {
    setNewPasswordField(true);
    setOtpsent(false);
  };

  const location = useLocation();

  return (
    <div className="new-bg d-flex vh-100 justify-content-center align-items-center">
      <div className="container">
        <div className="d-flex justify-content-center align-items-center mt-4">
          <div className="w-100 p-3 p-lg-0 p-md-0 p-sm-3" style={{ maxWidth: '400px' }}>
            <div className="logo d-flex justify-content-center align-items-center mb-3">
              <div className="mail-bg">
                <LuKeyRound size={35} />
              </div>
            </div>
            <div className="text-center mb-3">
              <h4 className="signup-title text-capitalize">Forgot Password?</h4>
            </div>
            <p className="m-0 text-center">
              No worries, we’ll send you reset instructions.
            </p>
            <div className="form-group mb-3 mt-32">
              <label htmlFor="email" className="signup-label mb-2">
                Email
              </label>
              <input
                type="email"
                className="form-control signup-input-field"
                placeholder="Enter Email"
                name="email"
                id="email"
                onChange={updateData}
                value={data.email}
                disabled={disable}
              />
              <small className="text-danger ml-2">{messages.emailError}</small>
              <p className="text-success ml-2 my-2 text-center">{messages.emailSuccess}</p>
              {messages.emailSuccess && (
                <p className="check-mail-msg my-2 text-center">{messages.emailCheck}</p>
              )}
            </div>
            {loading === true ? (
              <button aria-label="Sign Up" className="signup-btn w-100" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            ) : (
              <button
                className={
                  data.email === "" || disable
                    ? "signup-btn-disable mt-2 get-started w-100"
                    : "signup-btn mt-2 w-100"
                }
                onClick={resetPassword}
              >
                Reset Password
              </button>
            )}

            <div className="container p-3 d-flex justify-content-center align-items-center pt-4">
              <button
                className="ms-2 back-to-event border-0 d-flex align-items-center gap-2"
                onClick={() => {
                  navigate("/login");
                }}
              >
                <IoMdArrowRoundBack size={22} className="first-line-icon" />
                Back to Login
              </button>
            </div>

            {/* <h5>
        Remember password?{" "}
        <Link to="/login">
          <span>Login</span>
        </Link>
      </h5> */}
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
      >
        <Modal.Body className="p-0">
          <div className="forgot-modal new-bg p-5 rounded">
            <div className="logo d-flex justify-content-center align-items-center mb-3">
              <div className="mail-bg">
                <FiMail size={48} />
              </div>
            </div>
            <div className="text-center mb-4 verification-text">
              <div>We sent a verification link to{" "}</div>
              <strong style={{ fontWeight: "500 !important" }}>
                {/* {state ? state.email : signupDetails.data.email} */}
              </strong>
            </div>
            <div className="d-flex justify-content-center">
              <button aria-label="Email" className="get-started w-50 my-2 text-center text-decoration-none">
                Open Email App
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  );
}
