import React, { useEffect, useState } from "react";
import "./css/LoginDM.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { doorManager, setDoorManagerLink } from "../../redux/user";
import { devApi } from "../../utils/constants";
import logomark from "../../Assets/Logomark.svg";
import axios from "axios";
import moment from "moment";
const regForEmail = RegExp(
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
);

export default function LoginDM() {
  const [showPassword, setShowPassword] = useState(false);
  const [eventTitle, setEventTitle] = useState();
  const [loading, setLoading] = useState(false);
  const [logindata, setLogindata] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    credentials: "",
    dmId: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dmId, setDmId] = useState(null);

  useEffect(() => {
    const queryString = window.location.search;
    dispatch(setDoorManagerLink(queryString));

    const urlParams = new URLSearchParams(queryString);
    const dmParam = urlParams.get("dm");

    if (!dmParam) {
      navigate("/invalidlink");
    } else {
      setDmId(parseInt(dmParam, 10));
    }
  }, [dispatch, navigate]);

  const getEventsData = async () => {
    try {
      const response = await axios.get(`${devApi}/api/e/fetchEventsData?dm=${dmId}`);
      if (response.data) {
        console.log(response.data);
        setEventTitle(response.data.events);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (dmId !== null) {
      getEventsData();
    }
  }, [dmId]);

  const updateData = (e) => {
    let { name, value } = e.target;
    setLogindata({
      ...logindata,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
    switch (name) {
      case "username":
        setErrors({
          ...errors,
          username: value.length === 0 ? "Username is required" : "",
        });
        break;
      case "password":
        setErrors({
          ...errors,
          password: value.length === 0 ? "Password is required" : "",
        });
        break;
      default:
        break;
    }
  };
  const checkError = () => {
    const newErrors = { ...errors };
    let dataIsValid = true;
    if (!logindata.username) {
      dataIsValid = false;
      newErrors.username = "Username is required";
    } else {
      newErrors.username = "";
    }
    if (!logindata.password) {
      dataIsValid = false;
      newErrors.password = "Passwrod is required";
    } else {
      newErrors.password = "";
    }
    if (!dataIsValid) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const logins = async (e) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        if (!checkError()) {
          return;
        }
        const body = {
          username: logindata.username,
          password: logindata.password,
          door_management_id: dmId,
        };
        await axios
          .post(`${devApi}/api/dm/doorManagerLogin`, body)
          .then((res) => {
            if (res.data.success) {
              console.log(res.data);
              if (res.data.doorManager.admin_entry_closed) {
                setErrors({
                  ...errors,
                  error: "Entries are closed. Please contact your Admin",
                });
              } else if (res.data.doorManager.logout) {
                setErrors({
                  ...errors,
                  error: "You are Logged out",
                });
              } else {
                dispatch(
                  doorManager({
                    token: res.data.token,
                    user: res.data.doorManager,
                  })
                );
                navigate(`/camerascanner`, {
                  state: { entry_closed: res.data.doorManager.entry_closed },
                });
              }
            } else {
              setErrors({
                ...errors,
                error: res.data.message,
              });
            }
          })
          .catch((error) => {
            setErrors({
              ...errors,
              credentials: error.response.data.message,
            });
          });
      } catch (error) {
        setErrors({ ...errors, credentials: error.response.data.message });
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  return dmId ? (
    <div className="root-container d-flex align-items-center justify-content-center mt-10">
      <div className="logindm-page">
        <div className="logo d-flex justify-content-center align-items-center mb-1">
          <Link to={"/"}>
            <img
              src={logomark}
              alt="Eventgem"
              width={60}
              // className="logomark-border"
            />
          </Link>
        </div>
        <div className="my-3">
          <h4 className="title-doormanager">Log in to Your Account</h4>
          <p className="checkout-login-desc">Start door management for <span className="doormanager-login-title">{eventTitle && (eventTitle.title)}</span></p>
        </div>

        {/* Form */}
        <div className="container d-flex justify-content-center w-100">
          <div className="row align-items-center justify-content-center w-100">
            <div className="col-12 col-md-6 col-lg-4">
              {/* Username Input */}
              <div className="form-group mb-3">
                <label htmlFor="username" className="signup-label mb-1">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control signup-input-field"
                  placeholder="Enter your username"
                  name="username"
                  onChange={updateData}
                  value={logindata.username}
                  id="username"
                  autoComplete="off"
                />
                <small className="text-danger">{errors.username}</small>
              </div>

              {/* Password Input */}
              <div className="form-group mb-3">
                <label htmlFor="password" className="signup-label mb-1 d-flex justify-content-between">
                  <span>Password</span>
                </label>
                <div className="position-relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control signup-input-field pe-5"
                    placeholder="Enter Password"
                    name="password"
                    id="password"
                    onChange={updateData}
                    value={logindata.password}
                  />
                  <button
                    type="button"
                    className="btn position-absolute top-50 end-0 translate-middle-y me-2 p-0 border-0 bg-transparent"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}
                  </button>
                </div>
                <small className="text-danger">{errors.password}</small>
              </div>

              {/* Forgot Password */}
              {/* <div className="d-flex justify-content-end align-items-center my-3">
                <div className="d-flex align-items-center m-0">
                  <Link to="/forgotpassword" className="text-decoration-none">
                    <h6 className="forgot-text m-0">Forgot Password</h6>
                  </Link>
                </div>
              </div> */}

              <div className="form-box d-flex justify-content-center">
                {errors.credentials && (
                  <small className={"text-danger ml-2"}>{errors.credentials}</small>
                )}
                {errors.error && (
                  <small className="text-danger ml-2">
                    {errors.error}
                    {errors.error.includes("Door manager can start login by") && moment(eventTitle.start_date).isAfter(moment()) && (
                      " Reach out to the Event organizer to change the event time."
                    )}
                  </small>
                )}
                {errors.dmId && (
                  <small className={"text-danger ml-2"}>{errors.dmId}</small>
                )}
              </div>

              {/* Login Button */}
              {loading ? (
                <div className="d-flex justify-content-center align-items-center mt-2 w-100">
                  <button className="signup-btn w-100" style={{ maxWidth: "400px" }} type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center mt-2 w-100">
                  <div className="w-100" style={{ maxWidth: "400px" }}>
                    <button
                      className="btn w-100 get-started"
                      style={{ maxWidth: "400px" }}
                      onClick={() => {
                        const isValid = checkError();
                        if (!isValid) {
                          return;
                        }
                        logins();
                      }}
                    >
                      Log in
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
// {
//     "success": true,
//     "message": "QR code scanned successfully",
//     "attendee": {
//         "id": 51674,
//         "order_id": 50944,
//         "event_id": 29,
//         "ticket_id": 48,
//         "first_name": "Jai",
//         "last_name": "Kameih",
//         "email": "jaikameih@gmail.com",
//         "phone": null,
//         "private_reference_number": "UGOboYeuv3t6vE9",
//         "created_at": "2024-07-18 16:01:51",
//         "updated_at": "2024-07-23 04:08:47",
//         "deleted_at": null,
//         "is_cancelled": false,
//         "has_arrived": 1,
//         "arrival_time": "2024-07-23 04:08:47",
//         "account_id": 1,
//         "reference_index": 6,
//         "is_refunded": false,
//         "is_scaned": true,
//         "is_scaned_by": 3,
//         "is_scan_sucess": true,
//         "door_management_id": null,
//         "paper_ticket": 0
//     },
//     "door_manager": {
//         "id": 3,
//         "phone": null,
//         "email": "sumant.thakre@deployd.io",
//         "username": "sumant.thakre@deployd.io",
//         "event_id": 29,
//         "organiser_id": 12,
//         "account_id": 1,
//         "created_at": "2024-07-08 11:48:01",
//         "updated_at": "2024-07-23 04:06:51",
//         "door_management_id": 2,
//         "first_name": "Sumant",
//         "last_name": "Thakre",
//         "entry_closed": 0,
//         "logout": 0,
//         "admin_entry_closed": 1
//     },
//     "ticket": {
//         "id": 48,
//         "created_at": "2024-06-19 18:23:35",
//         "updated_at": "2024-07-22 11:33:31",
//         "deleted_at": null,
//         "edited_by_user_id": null,
//         "account_id": 1,
//         "order_id": null,
//         "event_id": 29,
//         "title": "Adults",
//         "description": "Ages 13 & up",
//         "price": "12.00",
//         "max_per_person": 30,
//         "min_per_person": 1,
//         "quantity_available": null,
//         "quantity_sold": 15147,
//         "start_sale_date": "2024-06-19 18:23:35",
//         "end_sale_date": null,
//         "sales_volume": "1764.00",
//         "organiser_fees_volume": "0.00",
//         "is_paused": 0,
//         "public_id": null,
//         "user_id": 1,
//         "sort_order": 0,
//         "is_hidden": 0,
//         "eventext_id": 22,
//         "early_bird": 0,
//         "full_price": "0.00"
//     }
// }