import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoAlertCircleOutline } from "react-icons/io5";
import "./Payments.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useSelector } from "react-redux";
export default function PaymentFailed() {
  const navigate = useNavigate();
  // const { inputValue } = useParams();
  const eventId = useSelector((state) => state.user.eventId);
  const handleNavigate = () => {
    navigate(`/checkout?from=event-${eventId}`);
  };
  return (
    <div className="new-bg">
      <div className="container">
        <div className="container pt-4 mb-3 d-flex align-items-center">
          {/* Back Button */}
          <div className="d-flex align-items first-line-links-center justify-content-center">
            <button className="d-flex back-to-event align-items-center gap-2 border-0 bg-transparent" onClick={handleNavigate}>
              <IoMdArrowRoundBack size={22} className="first-line-icon" />
              <span className="d-flex">Back to Events</span>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <div className="title-payment text-center">
              Booking Unsuccessful !
            </div>
            <p className="success-para text-center">
              Sorry, we could not book your tickets due to payment failure.
            </p>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12 payment-box mb-4 p-3 confirm-booking border-0 rounded-bg">
              <div className="event-order-box pb-0">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h5 className="confirmed-h5 mb-2 mt-3 w-100 d-flex gap-1 justify-content-center align-items-center">
                    <IoAlertCircleOutline size={24} style={{ color: "#F98A45" }} />
                    Payment Failed
                  </h5>
                </div>

                {/* Order Details --- needs to be update */}
                {/* <div className="under-ticket1 text-center my-2 w-100">
                  <h6>{orderDetails.orders[0].event.title}</h6>
                  <h6>Garba Mahotsav</h6>
                  <div className="d-flex flex-column gap-2">
                    <div className="booking-data row gap-2 justify-content-center">
                      <div className="col-auto d-flex gap-1 align-items-center">
                        <IoCalendarClearOutline size={14} />
                        <span
                          style={{ fontSize: "12px", lineHeight: "18px" }}
                        >
                          {orderDetails.orders[0].event.startday}
                        </span>
                      </div>
                      <div className="col-auto d-flex gap-1 align-items-center">
                        <FaRegClock size={14} />
                        <span
                          style={{ fontSize: "12px", lineHeight: "18px" }}
                        >
                          {orderDetails.orders[0].event.starttime}
                        </span>
                      </div>
                    </div>

                    <div className="booking-data row justify-content-center my-1">
                      <div
                        className="col-12 col-md-auto d-flex gap-1 align-items-center justify-content-center text-center w-75"
                      >
                        <MdOutlineLocationOn size={18} className="flex-shrink-0" />
                        <span
                          className="text-wrap"
                          style={{ fontSize: "12px", lineHeight: "18px" }}
                        >
                          {orderDetails.orders[0].event.venue_name},{" "}
                          {orderDetails.orders[0].event.location_address_line_1},{" "}
                          {orderDetails.orders[0].event.location_address_line_2},{" "}
                          {orderDetails.orders[0].event.location_address_line2},{" "}
                          {orderDetails.orders[0].event.location_post_code}
                        </span>
                      </div>
                    </div>

                  </div>
                </div> */}

                {/* Booking Details */}
                {/* <div className="booking-details my-2">
                  <div className="event-order-box">
                    <div className="d-flex justify-content-between">
                      <div className="order-data m-0">
                        <h5>Booking Attempt </h5>
                        <h6 className="m-0">
                          <strong>
                            {moment(orderDetails.orders[0].system_datetime).format(
                              // "ddd DD, MMMM YYYY hh:mm A"
                              "DD MMM YYYY, HH:mm:ss"
                            )}
                          </strong>
                        </h6>
                      </div>
                      <div className="order-data m-0">
                        <h5>Booking ID:</h5>
                        <strong>
                          <h6 className="m-0">{orderDetails.orders[0].order_reference}</h6>
                        </strong>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="d-flex justify-content-center align-items-center mb-4 w-100">
                  <button onClick={handleNavigate} className="get-started w-100 text-decoration-none">
                    Try Again
                  </button>
                </div>
                <div className="contact-history text-center">
                  If you have any questions, please email us at {" "}
                  <Link to="mailto:info@eventgem.com" className="mail-id">info@eventgem.com</Link> {" "}
                  or reach out to the organizer {/* {viewMoreOrder.organizer.contact_name} at {viewMoreOrder.organizer.phone} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
